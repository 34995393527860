import { setAffiliateDataTable } from "store/ui/affiliate";
import http from "../../services/http";
import storage from "../../services/storage";
import { setAffiliatedUserTable } from "store/ui/affiliate";

export const AffiliateTableSearch = ({ pageNumber, pageSize, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        url: `/users/affiliate?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });
      dispatch(setAffiliateDataTable(res?.data));
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const AffiliatedUserSearch = ({ id, pageNumber, pageSize, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        url: `/users/affiliated/${id}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        headers: {
          "x-auth-token": storage.get("affiliateAuthToken"),
        },
      });
      dispatch(setAffiliatedUserTable(res?.data));
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const AffiliatedUserSearchAdmin = ({ id, pageNumber, pageSize, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        url: `/users/affiliated/${id}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });
      dispatch(setAffiliatedUserTable(res?.data));
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const AffiliateLogin = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.post({
        url: `/users/login_affiliate`,
        body,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const verifylogin = ({ contactInfo, verificationCode, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        url: `/users/verify_login/?contactInfo=${contactInfo}&verificationCode=${verificationCode}`,
      });
      // console.log("123", res?.headers["x-auth-token"], res?.data);
      const { data } = res;

      // console.log("12w3", data);

      // const xAuthToken = headers["x-auth-token"];
      // dispatch(setToken(token));
      // dispatch(setUser(data));

      storage.store("adminUser", data);

      storage.store("affiliateAuthToken", res.headers["x-auth-token"]);

      onSuccess(res);
      //   dispatch(showLoginLoader(false));
    } catch (error) {
      //   dispatch(showLoginLoader(false));

      if (onError) onError(error);
    }
  };
};
