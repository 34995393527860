import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";
import { useDispatch } from "react-redux";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgSignIn from "assets/images/tree-bg.png";
import { login } from "store/api/auth";
import Toast from "helpers/Toast";
import storage from "services/storage";
import { useHistory } from "react-router-dom";
import { basePath } from "config";
import { CircularProgress, FormHelperText, Grid } from "@mui/material";
// import VuiSwitch from "components/VuiSwitch";

function Dev() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [rememberMe, setRememberMe] = useState(true);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  useEffect(() => {
    const isChecked = storage.get("isChecked");

    setCheck(isChecked);
  }, []);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleChange2 = (e) => {
    // const { checked } = e.target;
    // storage.store("baseUrlPath", checked ? "production" : "dev");
    // storage.store("isChecked", checked ? "true" : "false");
    e.preventdefault();
    // setCheck(checked);
    storage.store("baseUrlPath", "production");
  };
  const handleChange3 = (e) => {
    // const { checked } = e.target;
    // storage.store("baseUrlPath", checked ? "production" : "dev");
    // storage.store("isChecked", checked ? "true" : "false");

    // setCheck(checked);
    e.preventdefault();
    storage.store("baseUrlPath", "dev");
  };

  const initialValues = {
    email: "",
    password: "",
    production: false,
  };
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string().required("Required"),
  });
  const onSubmit = async (values) => {
    setLoading(true);
    // dispatch(setCredational(values));

    dispatch(
      login({
        body: {
          email: values.email,
          password: values.password,
        },
        onSuccess: (res) => {
          // navigate("/lobby");
          const { headers, data } = res;
          setLoading(false);
          const adminUser = data;
          if (adminUser?.userType !== 2) {
            Toast.warning("you are not authorized for this area.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else {
            if (adminUser?.isEmailVerified === true) {
              Toast.success("Welcome to Admin Panel.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              storage.store("adminUser", data);
              storage.store("adminAuthToken", headers["x-auth-token"]);
              // this.props.history.replace(basePath + "/users");
              history.push(basePath + "/dashboard");
            } else {
              Toast.warning("It seems like your email is not verified.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          }
        },

        onError: (error) => {
          setLoading(false);

          Toast.error(error.response?.data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
  };
  return (
    <CoverLayout
      title="Tree | Admin Panel"
      color="white"
      description="Select Your Role to Continue"
      // premotto="INSPIRED BY THE FUTURE:"
      // motto="Tree Admin"
      image={bgSignIn}
    >
      <VuiBox>
        <div className="toggle-Button ">
          <button
            onClick={() => {
              history.push(basePath + "/signin");
            }}
            className="dev"
          >
            Admin
          </button>
          <button
            onClick={() => {
              history.push(basePath + "/signin-affiliate");
            }}
            className="pro"
          >
            Affiliate
          </button>
        </div>
      </VuiBox>
    </CoverLayout>
  );
}

export default Dev;
// <VuiBox>
//         <div className="toggle-Button ">
//           <button
//             onClick={() => {
//               storage.store("baseUrlPath", "dev");
//               history.push(basePath + "/signin");
//               window.location.reload();
//             }}
//             className="dev"
//           >
//             Development
//           </button>
//           <button
//             onClick={() => {
//               storage.store("baseUrlPath", "production");
//               history.push(basePath + "/signin");
//               window.location.reload();
//             }}
//             className="pro"
//           >
//             Production
//           </button>
//         </div>
//       </VuiBox>
