import { basePath } from "config";
import React from "react";
import { Redirect } from "react-router-dom";
import storage from "services/storage";

const AffiliateGuard = (Element) => {
  return (props) => {
    const affiliate = storage.get("adminUser");
    const affiliateUser = affiliate.userType === 3;
    console.log("affiliateUser", affiliateUser);

    if (affiliateUser) {
      console.log("true");

      return <Element {...props} />;
    } else {
      console.log("false");

      return <Redirect to={`${basePath}/signin-select`} {...props} />;
    }
  };
};

export default AffiliateGuard;
