import { basePath } from "config";
import React from "react";
import { Redirect } from "react-router-dom";
const AuthGuard = (Element) => {
  return (props) => {
    const token = localStorage.getItem("adminAuthToken");

    if (token) {
      return <Element {...props} />;
    } else {
      return <Redirect to={`${basePath}/signin-select`} {...props} />;
    }
  };
};

export default AuthGuard;
