import VuiInput from "components/VuiInput";
import Toast from "helpers/Toast";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { postCreateAffiliateUser } from "store/api/userTable";

export default function LimitInput({ dataLimit, userId }) {
  const [localLimit, setLocalLimit] = useState(dataLimit);
  const dispatch = useDispatch();

  return (
    <VuiInput
      value={localLimit}
      //   key={row?._id}
      // value={localLimit}
      // onChange={(e) => {
      //   setLocalLimit(e.target.value);

      //   console.log(localLimit);
      // }}
      onChange={async (newlimit) => {
        // console.log(cellData);
        setLocalLimit(newlimit.target.value);
      }}
      sx={{
        maxWidth: "50px",
      }}
      onKeyDown={async (e) => {
        if (e.key === "Enter") {
          dispatch(
            postCreateAffiliateUser({
              body: {
                couponCode: "",
                couponLimit: localLimit,
                userId: userId,
              },
              onSuccess: (res) => {
                Toast.success(res.data, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
              },
              onError: (error) => {
                setLocalLimit(dataLimit);

                Toast.error("Something went wrong", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
              },
            })
          );
        }
      }}
    />
  );
}
