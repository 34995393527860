import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import Table from "examples/Tables/Table";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
// import DataTable from "react-data-table-component";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getUserTable } from "store/api/userTable";
// import VipTableData from "./data/VipTableData";
import { CircularProgress, FormControl, MenuItem, Pagination, Select } from "@mui/material";
// import VuiInput from "components/VuiInput";
// import VuiButton from "components/VuiButton";
// import { Link } from "react-router-dom";
// import { getvipTable } from "store/api/userTable";
// import { getGlobalCouponLimit } from "store/api/userTable";
// import { postGlobalCouponLimit } from "store/api/userTable";
import Toast from "helpers/Toast";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import VuiButton from "components/VuiButton";
import csv from "assets/images/csv3.png";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
// import { ProgressBar } from "@ramonak/react-progress-bar";
import { useHistory, useParams } from "react-router-dom";

import ProgressBar from "@ramonak/react-progress-bar";
import { basePath } from "config";
import { SiSoundcloud } from "react-icons/si";
import { PiTiktokLogoFill } from "react-icons/pi";
import { IoLogoYoutube } from "react-icons/io";
import { FaSquareInstagram } from "react-icons/fa6";
import VuiInput from "components/VuiInput";
import { getUserTable } from "store/api/userTable";
import VuiAvatar from "components/VuiAvatar";
import { sendBulkData } from "store/api/bulk";
import { Link } from "react-router-dom";
import profile from "assets/images/profile.png";
import { getUser } from "store/api/auth";
import { TagsInput } from "react-tag-input-component";

const VisuallyHiddenInput = styled("input")({
  // clip: "rect(0 0 0 0)",
  // clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  // color: "#fff",
  width: 1,
});
const selectPlatformType = [
  {
    name: "instagram",
    icon: <FaSquareInstagram color="#fff" size={28} />,
  },
  {
    name: "youtube",
    icon: <IoLogoYoutube color="#fff" size={28} />,
  },
  {
    name: "tiktok",
    icon: <PiTiktokLogoFill color="#fff" size={28} />,
  },
  // {
  //   name: "soundcloud",
  //   icon: <SiSoundcloud color="#fff" size={28} />,
  // },
];
const handleSelectColor = (item) => {
  setSelectColor(item.color);
  // toast.info(`You select ${item.name} color  `);
};
function AddBulkDataID() {
  const id = useParams();
  console.log("id==>", id.id);
  const history = useHistory();
  const dispatch = useDispatch();
  const { userListing } = useSelector((state) => state.userDataTable);
  // console.log("userListing", userListing);
  const [selected, setSelected] = useState([]);

  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);
  const [loading, setloading] = useState(false);
  const [loading2, setloading2] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectColor, setSelectColor] = useState("");
  const [settingUser, setSettingUser] = useState("");
  const [userInfo, setUserInfo] = useState({});

  const handleSelectColor = (item) => {
    // console.log(item);
    setSelectColor(item.name);
    // toast.info(`You select ${item.name} color  `);
  };
  const getUserOnClick = (item) => {
    // console.log(item);
    // setSelectColor(item.name);
    setSettingUser(item);
    // toast.info(`You select ${item.name} color  `);
  };
  getUser;
  useEffect(() => {
    console.log(id);
    dispatch(
      getUser({
        id: id.id,
        onSuccess: (res) => {
          console.log("res,", res);
          setUserInfo(res.data);
        },
      })
    );
  }, []);
  useEffect(() => {
    setloading2(true);
    dispatch(
      getUserTable({
        draw: 1,
        search: "",
        length: 200,
        start: 0,
        onSuccess: (res) => {
          // console.log(res);
          setloading2(false);

          // setLoading(false);
          // setData(res.data.data);
          // setPg(Math.ceil(res.data.recordsFiltered / Number(length)));
        },
        onError: (err) => {
          setloading2(false);
        },
      })
    );
  }, []);
  const handleInputChange = (event) => {
    setloading2(true);
    dispatch(
      getUserTable({
        draw: 1,
        search: event.target.value,
        length: 200,
        start: 0,
        onSuccess: (res) => {
          // console.log(res);
          setloading2(false);

          // setLoading(false);
          // setData(res.data.data);
          // setPg(Math.ceil(res.data.recordsFiltered / Number(length)));
        },
        onError: (err) => {
          setloading2(false);
        },
      })
    );
    // if (event.key === "Enter") {
    // setSearch(event.target.value);
    // setpages(1);
    // }
  };
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = () => {
    // Simulating progress for demo purposes
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + 10; // Increment by 10% in each interval
      });
    }, 1000);
  };
  const [name, setName] = useState("");
  const handleClickChangeAvatar = (e) => {
    e.preventDefault();
    document.getElementById("imageInput").click();
  };
  const handleCsv = (event) => {
    const file = event.target.files[0];
    // setImage(file);
    // setFieldValue("image", file);
    // console.log("file===>", file.name);
    // console.log("file===>3", event);
    setFile2(file);
    setName(file.name);
    // const interval = setInterval(() => {
    //   setProgress((prevProgress) => {
    //     if (prevProgress >= 100) {
    //       clearInterval(interval);
    //       return 100;
    //     }
    //     return prevProgress + 10; // Increment by 10% in each interval
    //   });
    // }, 1000);
  };
  const uploadCsv = () => {
    // console.log("file===>");
    const formData = new FormData();
    const jsonString = JSON.stringify(selected);

    formData.append("file", file2);
    formData.append("tags", jsonString);

    formData.append("platform", selectColor);
    if (settingUser._id) {
      // console.log("121212");
      // console.log("123123123", settingUser._id);
      formData.append("owner", settingUser._id);
    } else {
      // console.log("123123123112312313", id.id);
      formData.append("owner", id.id);
      // console.log("1213");
    }
    if (file2 === null) {
      Toast.error("JSON file is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else if (selectColor === "") {
      Toast.error("Platform is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else if (id.id === "" || settingUser._id === "") {
      // console.log("s", id);
      Toast.error("user is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      setloading(true);

      dispatch(
        sendBulkData({
          body: formData,
          onSuccess: (res) => {
            // console.log("res", res?.data?.data?.owner);
            const redirectId = res?.data?.data?.owner;
            setloading(false);
            history.push(basePath + "/profile/" + redirectId);
            Toast.success("Uploaded successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          },
          onError: (err) => {
            // console.log("err", err);
            setloading(false);
            Toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          },
        })
      );
    }
    // setloading(true);

    // const interval = setInterval(() => {
    //   setProgress((prevProgress) => {
    //     if (prevProgress >= 100) {
    //       clearInterval(interval);
    //       return 100;
    //     }
    //     return prevProgress + 15; // Increment by 10% in each interval
    //   });
    // }, 1000);
    // setTimeout(() => {
    // history.push(basePath + "/bulk-data");
    //   Toast.success("CSV uploaded successfully", {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    //   });
    // }, 10000);
  };

  const handleKeyDown = (event) => {
    if (event.key === " " && event.target.value.trim() !== "") {
      // Prevent the space from being added to the input value
      event.preventDefault();
      // Add the current input as a new tag
      setSelected([...selected, event.target.value.trim()]);

      // Clear the input value
      event.target.value = "";
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={0} sx={{ minHeight: "74vh" }}>
          <Card>
            <VuiBox mb="22px">
              <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
                <VuiTypography variant="lg" color="white">
                  Content Post
                </VuiTypography>
                <VuiBox mr={2}>
                  <VuiButton color="info" component={Link} to={`${basePath}/add-user`}>
                    <VuiTypography variant="button" color="white" fontWeight="regular">
                      Add New User
                    </VuiTypography>
                  </VuiButton>
                </VuiBox>
                <VuiBox mr={12}>
                  <VuiButton color="info" component={Link} to={`${basePath}/bulk-data`}>
                    <VuiTypography variant="button" color="white" fontWeight="regular">
                      Uploaded JSON
                    </VuiTypography>
                  </VuiButton>
                </VuiBox>
              </VuiBox>
              <div className="bulk-wrap">
                <VuiBox
                  mt={3}
                  // display="flex"
                  // flexDirection="column"
                  // justifyContent="center"
                  // alignItems="center"
                >
                  <div className="sx-flex">
                    <div className="sx2-flex">
                      <VuiTypography
                        sx={{
                          cursor: "pointer",
                          marginBottom: "2%",
                        }}
                        variant="lg"
                        color="white"
                        onClick={handleClickChangeAvatar}
                      >
                        Choose JSON File
                      </VuiTypography>
                      <div
                        className="csv"
                        style={{
                          cursor: "pointer",
                          marginBottom: "2%",
                        }}
                      >
                        <img src={csv} onClick={handleClickChangeAvatar} />
                        <input
                          type="file"
                          accept="application/json"
                          onChange={handleCsv}
                          style={{ display: "none" }}
                          id="imageInput"
                        />
                      </div>

                      <VuiTypography
                        color="white"
                        sx={{
                          // cursor: "pointer",
                          fontSize: "14px",
                          marginBottom: "2%",
                        }}
                      >
                        {name}
                      </VuiTypography>
                    </div>
                    {/* <h1>{name}</h1> */}
                    <div className="wrap-platform">
                      <VuiTypography
                        sx={{
                          cursor: "pointer",
                          marginBottom: "2%",
                          textAlign: "center",
                        }}
                        variant="lg"
                        color="white"
                        // onClick={handleClickChangeAvatar}
                      >
                        Choose Platform
                      </VuiTypography>
                      {/* <div className="wrap-preset-input">
                        <label></label>
                      </div> */}
                      <div className="wrap-fileType">
                        {selectPlatformType?.map((item) => {
                          return (
                            <div
                              className={`"filType3"" ${
                                selectColor === item.name ? "active696" : "filType3"
                              }`}
                              onClick={() => handleSelectColor(item)}
                            >
                              {item.icon}
                            </div>
                          );
                        })}
                      </div>
                      {settingUser ? (
                        <div className="wrap-selected-user">
                          <VuiTypography
                            sx={{
                              cursor: "pointer",
                              marginBottom: "2%",
                              textAlign: "center",
                            }}
                            variant="lg"
                            color="white"
                            // onClick={handleClickChangeAvatar}
                          >
                            Selected User
                          </VuiTypography>
                          <div className="selected-User">
                            <VuiBox display="flex" alignItems="center" px={1} py={0.5}>
                              <VuiBox mr={2}>
                                <VuiAvatar
                                  src={settingUser.image}
                                  alt={settingUser.name}
                                  size="sm"
                                  variant="rounded"
                                />
                              </VuiBox>
                              <VuiBox display="flex" flexDirection="column">
                                <VuiTypography variant="button" color="white" fontWeight="medium">
                                  {settingUser.name ? settingUser.name : settingUser.username}
                                </VuiTypography>
                                <VuiTypography variant="caption" color="text">
                                  {settingUser.email}
                                </VuiTypography>
                              </VuiBox>
                            </VuiBox>
                          </div>
                        </div>
                      ) : (
                        <div className="wrap-selected-user">
                          <VuiTypography
                            sx={{
                              cursor: "pointer",
                              marginBottom: "2%",
                              textAlign: "center",
                            }}
                            variant="lg"
                            color="white"
                            // onClick={handleClickChangeAvatar}
                          >
                            Selected User
                          </VuiTypography>
                          <div className="selected-User">
                            <VuiBox display="flex" alignItems="center" px={1} py={0.5}>
                              <VuiBox mr={2}>
                                <VuiAvatar
                                  src={userInfo?.image}
                                  //   alt={settingUser.name}
                                  size="sm"
                                  variant="rounded"
                                />
                              </VuiBox>
                              <VuiBox display="flex" flexDirection="column">
                                <VuiTypography variant="button" color="white" fontWeight="medium">
                                  {userInfo?.name}
                                </VuiTypography>
                                <VuiTypography variant="caption" color="text">
                                  {userInfo?.email}
                                </VuiTypography>
                              </VuiBox>
                            </VuiBox>
                          </div>
                        </div>
                      )}
                      <div className="wrap-array-input">
                        <label>Tag</label>
                      </div>
                      <TagsInput
                        value={selected}
                        onChange={setSelected}
                        name="tag"
                        placeHolder="add tag"
                        onKeyDown={handleKeyDown}
                        onKeyUp={handleKeyDown}
                      />
                    </div>
                    <div className="abc34">
                      <VuiTypography
                        sx={{
                          cursor: "pointer",
                          marginBottom: "2%",
                          textAlign: "center",
                        }}
                        variant="lg"
                        color="white"
                      >
                        Search User
                      </VuiTypography>
                      <VuiInput
                        id="search-input"
                        name="search-input"
                        placeholder="Type here..."
                        onChange={handleInputChange}
                        // onKeyPress={handleKeyPress}
                        icon={{ component: "search", direction: "left" }}
                        sx={({ breakpoints }) => ({
                          [breakpoints.down("sm")]: {
                            maxWidth: "80px",
                          },
                          [breakpoints.only("sm")]: {
                            maxWidth: "80px",
                          },
                          backgroundColor: "info.main !important",
                        })}
                        // value={sea}
                      />

                      <>
                        <div className="wrap-userData">
                          {loading2 ? (
                            <>
                              <VuiBox
                                // container
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minHeight="25vh"
                              >
                                <CircularProgress />
                              </VuiBox>
                            </>
                          ) : (
                            <>
                              {userListing?.data ? (
                                <>
                                  {" "}
                                  {userListing?.data.map((item) => {
                                    return (
                                      <div
                                        className="userData"
                                        onClick={() => getUserOnClick(item)}
                                      >
                                        <VuiBox display="flex" alignItems="center" px={1} py={0.5}>
                                          <VuiBox mr={2}>
                                            <VuiAvatar
                                              src={item.image}
                                              alt={item.name}
                                              size="sm"
                                              variant="rounded"
                                            />
                                          </VuiBox>
                                          <VuiBox display="flex" flexDirection="column">
                                            <VuiTypography
                                              variant="button"
                                              color="white"
                                              fontWeight="medium"
                                            >
                                              {item.name ? item.name : item.username}
                                            </VuiTypography>
                                            <VuiTypography variant="caption" color="text">
                                              {item.email}
                                            </VuiTypography>
                                          </VuiBox>
                                        </VuiBox>
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    </div>
                  </div>

                  <div
                    className="dic-upload
                  "
                  >
                    <VuiBox>
                      {loading ? (
                        <>
                          <VuiButton
                            component="label"
                            color={"info"}
                            sx={{ fontSize: "16px" }}
                            startIcon={<CircularProgress size={23} />}
                          >
                            Upload JSON
                            {/* <VisuallyHiddenInput type="file" accept=".csv" onChange={handleCsv} /> */}
                          </VuiButton>
                        </>
                      ) : (
                        <>
                          <VuiButton
                            component="label"
                            color={"info"}
                            sx={{ fontSize: "16px" }}
                            onClick={uploadCsv}
                            disabled={loading ? true : false}
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload JSON
                            {/* <VisuallyHiddenInput type="file" accept=".csv" onChange={handleCsv} /> */}
                          </VuiButton>
                        </>
                      )}
                    </VuiBox>
                    {/* 
                    {loading ? (
                      <div style={{ width: "30%", marginTop: "3%" }}>
                        <ProgressBar
                          completed={progress}
                          height="10px"
                          borderRadius="5px"
                          marginBottom="10px"
                          bgColor="#0e61e4"
                          isLabelVisible={false}
                          baseBgColor="#e3e2e2"
                          labelColor="#91353"
                          transitionDuration=""
                          transitionTimingFunction="linear"
                          animateOnRender
                          maxCompleted={100}
                        />
                      </div>
                    ) : null} */}
                  </div>
                </VuiBox>
              </div>
            </VuiBox>
          </Card>
        </VuiBox>
      </VuiBox>

      <Footer />
    </DashboardLayout>
  );
}

export default AddBulkDataID;
