import Card from "@mui/material/Card";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, FormControl, MenuItem, Pagination, Select } from "@mui/material";
import Toast from "helpers/Toast";
import VuiButton from "components/VuiButton";
import csv from "assets/images/csv.jpeg";
import LinearProgress from "@mui/material/LinearProgress";
import { useHistory } from "react-router-dom";
import { basePath } from "config";
import { Link } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import storage from "services/storage";
import { profileImport } from "store/api/auth";

function BusinessImport() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);
  const [loading, setloading] = useState(false);

  const [name, setName] = useState("");
  const handleClickChangeAvatar = (e) => {
    e.preventDefault();
    document.getElementById("imageInput").click();
  };
  const handleCsv = (event) => {
    const file = event.target.files[0];

    console.log("file===>", file.name);
    setFile2(file);
    setName(file.name);
  };
  const uploadCsv = () => {
    if (name) {
      setloading(true);

      const admin = storage.get("adminUser");
      console.log("file===>3", file2);

      const formData = new FormData();
      formData.append("csv", file2);
      formData.append("owner", admin._id);
      dispatch(
        profileImport({
          body: formData,
          onSuccess: (res) => {
            console.log("res", res);

            setloading(false);
            history.push(basePath + "/business-import-data");

            Toast.success("Uploaded successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          },
          onError: (err) => {
            console.log("err", err);
            setloading(false);
            Toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          },
        })
      );
    } else {
      Toast.error("CSV file is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={0} sx={{ minHeight: "74vh" }}>
          <Card>
            <VuiBox mb="22px">
              <VuiBox display="flex" justifyContent="center" alignItems="center" mb="22px">
                <VuiBox mr={12}>
                  <VuiButton color="info" component={Link} to={`${basePath}/business-import-data`}>
                    <VuiTypography variant="button" color="white" fontWeight="regular">
                      Uploaded Business CSV
                    </VuiTypography>
                  </VuiButton>
                </VuiBox>
              </VuiBox>
              <div className="bulk-wrap">
                <VuiBox
                  mt={3}
                  // display="flex"
                  // flexDirection="column"
                  // justifyContent="center"
                  // alignItems="center"
                >
                  <div className="sx-flex">
                    <div className="sx2-flex">
                      <VuiTypography
                        sx={{
                          cursor: "pointer",
                          marginBottom: "2%",
                        }}
                        variant="lg"
                        color="white"
                        onClick={handleClickChangeAvatar}
                      >
                        Choose CSV File
                      </VuiTypography>
                      <div
                        className="csv"
                        style={{
                          cursor: "pointer",
                          marginBottom: "2%",
                        }}
                      >
                        <img src={csv} onClick={handleClickChangeAvatar} />
                        <input
                          type="file"
                          accept=".csv"
                          onChange={handleCsv}
                          style={{ display: "none" }}
                          id="imageInput"
                        />
                      </div>

                      <VuiTypography
                        color="white"
                        sx={{
                          // cursor: "pointer",
                          fontSize: "14px",
                          marginBottom: "2%",
                        }}
                      >
                        {name}
                      </VuiTypography>
                    </div>
                    {/* <h1>{name}</h1> */}
                  </div>
                </VuiBox>
                <VuiBox>
                  {loading ? (
                    <>
                      <VuiButton
                        component="label"
                        color={"info"}
                        sx={{ fontSize: "16px" }}
                        startIcon={<CircularProgress size={23} />}
                      >
                        Upload CSV
                      </VuiButton>
                    </>
                  ) : (
                    <>
                      <VuiButton
                        component="label"
                        // component={Link}
                        // to={`${basePath}/business-import-data`}
                        color={"info"}
                        sx={{ fontSize: "16px" }}
                        onClick={uploadCsv}
                        disabled={loading ? true : false}
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload CSV
                      </VuiButton>
                    </>
                  )}
                </VuiBox>
              </div>
            </VuiBox>
          </Card>
        </VuiBox>
      </VuiBox>

      <Footer />
    </DashboardLayout>
  );
}

export default BusinessImport;
