import { createSlice } from "@reduxjs/toolkit";
const contentDataTable = createSlice({
  name: "contentDataTable",
  initialState: {
    contentUserListing: {},
  },
  reducers: {
    setContentDataTable: (state, action) => {
      state.contentUserListing = action.payload; // Update the value in the store
    },
  },
});

export const { setContentDataTable } = contentDataTable.actions;
export default contentDataTable.reducer;
