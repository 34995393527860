import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AddUserForm from "./components/addUserForm";

const AddUser = () => {
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <VuiBox py={3}>
          <AddUserForm member="USER" />
        </VuiBox>
      </DashboardLayout>
    </>
  );
};

export default AddUser;
