import { Card, CircularProgress, Grid, Stack } from "@mui/material";

import React, { useEffect } from "react";

//=================Template imports================================

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";

// Icons
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";

//   React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

//   assets
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import { Formik, Field, FieldArray, Form } from "formik";
import { FaDeleteLeft } from "react-icons/fa6";
import { FaImage } from "react-icons/fa6";
// Page imports
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
// import { Formik, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { FormHelperText } from "@mui/material";
import { storeAddUser } from "store/api/auth";
import Toast from "helpers/Toast";
import { basePath } from "config";
import logo from "assets/images/logo.svg";
import { postPreset } from "store/api/category";
import { getPresetIcon } from "store/api/category";
import { FaFileAudio } from "react-icons/fa6";
import { TiDocumentText } from "react-icons/ti";
import { RiVideoFill } from "react-icons/ri";
import { TagsInput } from "react-tag-input-component";
import audio from "assets/images/pic3.png";
import text from "assets/images/pic2.png";
import image2 from "assets/images/pic1.png";
import video from "assets/images/pic4.png";
import a from "assets/images/a.png";
import b from "assets/images/b.png";
import { toast } from "react-toastify";

const AddCategoryForm = ({ member, userData }) => {
  const [loading, setLoading] = useState(false);
  const [arr, setArr] = useState([""]);
  const [image, setImage] = useState(null);
  const [show, setShow] = useState(true);
  const [file, setFile] = useState(null);
  const [isSelected, setIsSelected] = useState("");
  const [isSelectedContent, setIsSelectedContent] = useState([]);
  const [isFileType, setIsFileType] = useState("");
  const [selectColor, setSelectColor] = useState("");
  const [selectImage, setSelectImage] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [selected, setSelected] = useState([]);
  const { presetIcon } = useSelector((state) => state.categoryDataTable);
  const colorArray = [
    { color: "#D1D5DB ", name: "Red" },
    { color: "#34D399", name: "Green" },
    { color: "#34ADD3 ", name: "Blue" },
    { color: "#6639E8 ", name: "Yellow" },
    { color: "#919FB4 ", name: "Magenta" },
    { color: "#E839CC  ", name: "Magenta" },
    { color: "#E86D39  ", name: "Magenta" },

    { color: "#E83939  ", name: "Magenta" },
    { color: "#DAE839  ", name: "Magenta" },
    { color: "#51E839  ", name: "Magenta" },
  ];
  const selectContentType = [
    {
      name: "unlockable",
      // img: <FaFileAudio color="#fff" />,
      img: a,
    },
    {
      name: " digital",
      img: b,
    },
  ];
  const selectFileType = [
    {
      // name: ["audio"],
      name: "audio",
      // img: <FaFileAudio color="#fff" />,
      img: audio,
    },
    {
      // name: [" video"],
      name: " video",

      img: video,
    },
    {
      // name: ["image"],
      name: "image",
      img: image2,
    },
    {
      name: "texteditor",

      img: text,
    },
  ];

  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  }
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getPresetIcon({
        onSuccess: (res) => {},
        onError: (err) => {},
      })
    );
  }, []);

  const handleSelectFileType = (item) => {
    setIsSelected(item.name);
  };
  const handleSelectContentType = (id) => {
    // setIsSelectedContent(item.name);
    console.log(id);
    // Check if the image ID is already in the array
    const index = isSelectedContent.indexOf(id);

    if (index === -1) {
      // If not in array, add it
      setIsSelectedContent([...isSelectedContent, id]);
    } else {
      // If already in array, remove it
      const updatedImages = [...isSelectedContent];
      updatedImages.splice(index, 1);
      setIsSelectedContent(updatedImages);
    }
  };
  const isImageSelectedContent = (id) => isSelectedContent.includes(id);

  // selecting colors
  const handleSelectColor = (item) => {
    setSelectColor(item.color);
    // toast.info(`You select ${item.name} color  `);
  };
  const handleSelectIcon = (item) => {
    setSelectImage(item.image);
    console.log(item.imag);
    // toast.info(`You select Icon  `);
  };
  const handleClickChangeAvatar = (e) => {
    e.preventDefault();
    document.getElementById("set-preset-icon").click();
  };
  const handleKeyDown = (event) => {
    if (event.key === " " && event.target.value.trim() !== "") {
      // Prevent the space from being added to the input value
      event.preventDefault();
      // Add the current input as a new tag
      setSelected([...selected, event.target.value.trim()]);

      // Clear the input value
      event.target.value = "";
    }
  };
  const handleImageClick = (id) => {
    console.log(id);
    // Check if the image ID is already in the array
    const index = selectedImages.indexOf(id);

    if (index === -1) {
      // If not in array, add it
      setSelectedImages([...selectedImages, id]);
    } else {
      // If already in array, remove it
      const updatedImages = [...selectedImages];
      updatedImages.splice(index, 1);
      setSelectedImages(updatedImages);
    }
  };

  const isImageSelected = (id) => selectedImages.includes(id);
  const formdata = () => {
    return (
      <Formik
        initialValues={{
          duration: "",
          name: "",
        }}
        onSubmit={(values) => {
          // setLoading(true);
          console.log(isSelectedContent);
          if (selectImage === "") {
            Toast.error("Icon is required", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            setLoading(false);

            const jsonString = {
              tags: JSON.stringify(selected),
              fileType: JSON.stringify(isSelected),
              contentType: JSON.stringify(isSelectedContent),
              name: values.name,
              image: selectImage,
              duration: values.duration,
              color: selectColor,
            };
            const body = JSON.stringify(jsonString);
          } else {
            // // Handle form submission
            // const formData = new FormData();
            // // formData.append("ss", values);
            // for (const key in values) {
            //   if (Object.hasOwnProperty.call(values, key)) {
            //     const value = values[key];
            //     console.log(typeof value);

            //     formData.append(key, typeof value === "object" ? JSON.stringify(value) : value);
            //   }
            // }
            // formData.append("file", image);
            // console.log(formData.entries(), "--", file);
            // for (let pair of formData.entries()) {
            //   console.log(pair[0], pair[1]);
            // }
            // console.log(Object.fromEntries(formData), values);

            // dispatch(
            //   postPreset({
            //     body: formData,
            //     onSuccess: (res) => {
            //       history.push(`${basePath}/feed-preset`);
            //       Toast.success("Added Successfully ", {
            //         position: "top-right",
            //         autoClose: 5000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //         theme: "dark",
            //       });
            //     },
            //     onError: (err) => {
            //       Toast.error("Something went wrong", {
            //         position: "top-right",
            //         autoClose: 5000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //         theme: "dark",
            //       });
            //     },
            //   })
            // );
            const jsonString = {
              tags: JSON.stringify(selected),
              fileType: JSON.stringify(selectedImages),
              contentType: JSON.stringify(isSelectedContent),
              name: values.name,
              image: selectImage,
              duration: values.duration,
              color: selectColor,
            };
            console.log("asdasd======>", jsonString);
            const body = JSON.stringify(jsonString);

            console.log("====>>>", body);
            dispatch(
              postPreset({
                body: jsonString,
                onSuccess: (res) => {
                  history.push(`${basePath}/feed-preset`);
                  Toast.success("Added Successfully ", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                  });
                  setLoading(false);
                },
                onError: (err) => {
                  Toast.error("Something went wrong", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                  });
                  setLoading(false);
                },
              })
            );
          }
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, errors, touched }) => (
          <>
            {/* <button onClick={handleClickChangeAvatar} className="btnChangeAvatar">
                <span>Change Avatar</span>
              </button> */}
            <div className="wrap-preset-input">
              <label>Name</label>
              <input
                type="text"
                placeholder="name"
                fontWeight="500"
                onChange={handleChange("name")}
                onBlur={handleBlur("name")}
                value={values.name}
              />
            </div>
            <div className="wrap-preset-input ">
              <label>Choose Icon</label>
            </div>
            <div className="icons-wrap">
              {/* <div>
                    <div className="preset-icon">
                      {file === null ? (
                        <></>
                      ) : (
                        <>
                          <img src={file === null ? logo : file} />
                        </>
                      )}
                    </div>
                    <input
                      id="set-preset-icon"
                      style={{ display: "none" }}
                      type="file"
                      onChange={handleChange}
                    />
                    <button onClick={handleClickChangeAvatar} className="btnChangeAvatar">
                      <span>Choose icon</span>
                    </button>
                  </div> */}

              <div className="design-icon">
                {presetIcon?.images?.map((item) => {
                  return (
                    <div onClick={() => handleSelectIcon(item)}>
                      <img
                        className={`${selectImage === item.image ? "icon-size-d2" : "icon-size-d"}`}
                        src={item.image}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="selectTheme_wrapper">
              <div className="selectOption_left">
                <div className="wrap-preset-input">
                  <label>What do you want to see?</label>
                </div>
                <div className="wrap-fileType">
                  {selectFileType.map((item) => {
                    return (
                      <div
                        className={`"filType" ${
                          isImageSelected(item.name) ? "active69" : "filType"
                        }`}
                        // className={"filType"}
                        // onClick={() => handleSelectFileType(item)}
                        onClick={() => handleImageClick(item.name)}
                      >
                        <img className="we" src={item.img} />
                      </div>
                    );
                  })}
                  {selectContentType.map((item) => {
                    return (
                      <div
                        className={`"filType" ${
                          isImageSelectedContent(item.name) ? "active69" : "filType"
                        }`}
                        onClick={() => handleSelectContentType(item.name)}
                      >
                        <img className="we" src={item.img} />
                      </div>
                    );
                  })}
                </div>
              </div>
              {selectImage ? (
                <div className="selectOption_right">
                  <div className="wrap-preset-input">
                    <label>Selected Icon</label>
                  </div>
                  {/* <br /> */}
                  <div
                    className="final-icon"
                    style={{
                      backgroundColor: `${selectColor ? selectColor : "none"}`,
                    }}
                  >
                    <img className="icon-size-d2" src={selectImage ? selectImage : ""} />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* chose color  */}
            <div className="wrap-preset-input">
              <label>Choose color</label>
            </div>
            <div className="wrap-fileType">
              {colorArray?.map((item) => {
                return (
                  <div
                    style={{
                      backgroundColor: item.color,
                    }}
                    className={`filType-ChoseColor ${selectColor === item.color ? "active" : ""}`}
                    onClick={() => handleSelectColor(item)}
                  ></div>
                );
              })}
            </div>

            <div className="wrap-preset-input ">
              <label>Duration</label>
            </div>
            <div className="preset21">
              <div className="preset-radio">
                <label>
                  <Field type="radio" name="duration" class="checkbox" value="1" />1 min
                </label>
              </div>
              <div className="preset-radio">
                <label>
                  <Field type="radio" name="duration" class="checkbox" value="3" />3 min
                </label>
              </div>
              <div className="preset-radio">
                <label>
                  <Field type="radio" name="duration" class="checkbox" value="5" />5 min
                </label>
              </div>
              <div className="preset-radio">
                <label>
                  <Field type="radio" name="duration" class="checkbox" value="10" />
                  10 min
                </label>
              </div>
              <div className="preset-radio">
                <label>
                  <Field type="radio" name="duration" class="checkbox" value="0" />
                  no limit
                </label>
              </div>
            </div>

            {/* <FieldArray name="contentType">
              {({ push, remove }) => (
                <>
                  {values?.contentType?.map((tag, index) => (
                    <div key={index} className="wrap-preset-input">
                      <label>Content Type</label>
                      <Field name={`contentType[${index}]`} placeholder="contentType" />
                    </div>
                  ))}
                </>
              )}
            </FieldArray> */}
            {/* <FieldArray name="fileType">
              {({ push, remove }) => (
                <>
                  {values?.fileType?.map((tag, index) => (
                    <div key={index} className="wrap-preset-input">
                      <label>File Type</label>
                      <Field type="radio" name={`fileType[${index}]`} value="1" />
                    </div>
                  ))}
                </>
              )}
            </FieldArray> */}
            {/* <div className="wrap-preset-input">
              <label>country</label>
              <input
                type="text"
                placeholder="country"
                fontWeight="500"
                onChange={handleChange("location.country")}
                // onBlur={handleBlur("country")}
                value={values.location.country}
              />
            </div>
            <div className="wrap-preset-input">
              <label>State</label>
              <input
                type="text"
                placeholder="State"
                fontWeight="500"
                onChange={handleChange("location.state")}
                onBlur={handleBlur("color")}
                value={values.location.state}
              />
            </div>
            <div className="wrap-preset-input">
              <label>City</label>
              <input
                type="text"
                placeholder="City"
                fontWeight="500"
                onChange={handleChange("location.city")}
                // onBlur={handleBlur("color")}
                value={values.location.city}
              />
            </div>

            <div className="wrap-preset-input">
              <label>Postal Code</label>
              <input
                type="text"
                placeholder="postal Code"
                fontWeight="500"
                onChange={handleChange("location.postalCode")}
                // onBlur={handleBlur("color")}
                value={values.location.postalCode}
              />
            </div>
            <div className="wrap-preset-input">
              <label>Latitude</label>
              <input
                type="number"
                placeholder="latitude"
                fontWeight="500"
                onChange={handleChange("location.latitude")}
                // onBlur={handleBlur("color")}
                value={values.location.latitude}
              />
            </div>
            <div className="wrap-preset-input">
              <label>Longitude</label>
              <input
                type="number"
                placeholder="longitude"
                fontWeight="500"
                onChange={handleChange("location.longitude")}
                // onBlur={handleBlur("color")}
                value={values.location.longitude}
              />
            </div>
            <div className="wrap-preset-input">
              <label>Address</label>
              <input
                type="text"
                placeholder="address"
                fontWeight="500"
                onChange={handleChange("location.address")}
                // onBlur={handleBlur("color")}
                value={values.location.address}
              />
            </div> */}
            <div className="wrap-array-input">
              <label>Tag</label>
            </div>
            <TagsInput
              value={selected}
              onChange={setSelected}
              name="tag"
              placeHolder="add tag"
              onKeyDown={handleKeyDown}
              onKeyUp={handleKeyDown}
            />
            {/* <FieldArray name="tags">
              {({ push, remove }) => (
                <div>
                  <div className="wrap-array-input">
                    <label>Tag</label>
                  </div>
                  {values?.tags?.map((tag, index) => (
                    <div key={index} className="wrap-array-input">
                      <div style={{ position: "relative" }}>
                        <Field name={`tags[${index}]`} placeholder="add tag" />
                        <div className="remove-tag" onClick={() => remove(index)}>
                          <FaDeleteLeft />
                        </div>
                      </div>
                    </div>
                  ))}
                  <button type="button" className="add-tag" onClick={() => push("")}>
                    Add Tag
                  </button>
                </div>
              )}
            </FieldArray> */}
            <div className="submit-design-p">
              <button
                className="final-submit"
                type="submit"
                onClick={handleSubmit}
                disabled={loading ? true : false}
              >
                {loading ? <CircularProgress size={20} /> : " Submit"}
              </button>
            </div>
          </>
        )}
      </Formik>
    );
  };

  return (
    <Card
    // sx={({ breakpoints }) => ({
    //   [breakpoints.up("xxl")]: {
    //     maxHeight: "400px",
    //   },
    // })}
    >
      <VuiBox display="flex" flexDirection="column">
        <VuiTypography variant="lg" color="white" fontWeight="bold" mb="6px">
          Add Preset
        </VuiTypography>
        <VuiBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          sx={({ breakpoints }) => ({
            [breakpoints.only("sm")]: {
              alignItems: "center",
            },
          })}
          alignItems="center"
        >
          <GradientBorder borderRadius={borders.borderRadius.form} minWidth="70%" maxWidth="80%">
            <VuiBox
              component="form"
              role="form"
              borderRadius="inherit"
              sx={({ palette: { secondary } }) => ({
                backgroundColor: secondary.focus,
              })}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <div
                style={{
                  padding: "2%",
                }}
              >
                {formdata()}
              </div>
            </VuiBox>
          </GradientBorder>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default AddCategoryForm;
