import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import CatgoryTables from "layouts/catgoryTable";
import claimedBusiness from "layouts/claimedBusiness";
import AddBulkData from "layouts/contentBulk";

import vipTable from "layouts/vipTable";
import bulkTable from "layouts/bulkTable";
import BusinessImportDataTable from "layouts/businessImportTable";
import UploadedBusinessImportDataTable from "layouts/profileUploaded";
import viewBulkTable from "layouts/viewBulkTable";
import AddUser from "layouts/addUser";
import AddCategory from "layouts/addCategory";
import EditPreset from "layouts/editPreset";
import UserProfile from "layouts/userProfile";
import UpdateUser from "layouts/updateUser";
import BusinessImport from "layouts/businessImport";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import Dev from "layouts/authentication/dev";
import AffiliateSignIn from "layouts/authentication/affliate-sign";
import SignUp from "layouts/authentication/sign-up";
import { basePath } from "config";
import { IoBusinessSharp, IoRocketSharp } from "react-icons/io5";
import { IoIosDocument } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";
import { IoBuild } from "react-icons/io5";
import { BsCreditCardFill } from "react-icons/bs";
import { IoStatsChart } from "react-icons/io5";
import { MdCategory } from "react-icons/md";
import { RiVipCrown2Fill } from "react-icons/ri";
import { BiSolidUserDetail } from "react-icons/bi";
import { FaMailBulk } from "react-icons/fa";
import { IoHome } from "react-icons/io5";
import AuthGuard from "guards/authGuard";
import ProtectGuard from "guards/protectGuard";
import AddBulkDataID from "layouts/contentBulkID";
import { BiSolidBusiness } from "react-icons/bi";
import AffiliateDataTable from "layouts/AffiliateTable";
import AffiliateGuard from "guards/affiliateGuard";
import AffiliatedUserDataTable from "layouts/AffiliatedUserTable";
import AffiliatedUserDataTableAdmin from "layouts/AffiliatedUserTableAdmin";
const routes = [
  {
    type: "collapse",
    id: 1,
    name: "Dashboard",
    key: "dashboard",
    route: `${basePath}/dashboard`,
    icon: <IoHome size="15px" color="inherit" />,
    component: AuthGuard(Dashboard),
    noCollapse: true,
    affiliateType: false,
  },
  {
    type: "collapse",
    name: "User Tables",
    key: "Usertables",
    route: `${basePath}/Usertables`,
    icon: <BiSolidUserDetail size="15px" color="inherit" />,
    component: AuthGuard(Tables),
    noCollapse: true,
    affiliateType: false,
  },
  {
    type: "collapse",
    name: "Vips Tables",
    key: "Vips-tables",
    route: `${basePath}/Vips-tables`,
    icon: <RiVipCrown2Fill size="15px" color="inherit" />,
    component: AuthGuard(vipTable),
    noCollapse: true,
    affiliateType: false,
  },
  {
    type: "collapse",
    name: "Affiliate Tables",
    key: "Affiliate-tables",
    route: `${basePath}/Affiliate-tables`,
    icon: <RiVipCrown2Fill size="15px" color="inherit" />,
    component: AuthGuard(AffiliateDataTable),
    noCollapse: true,
    affiliateType: false,
  },
  {
    type: "collapse",
    name: "Affiliated User Tables",
    key: "Affiliate-tables",
    route: `${basePath}/affiliate-User-tables`,
    icon: <RiVipCrown2Fill size="15px" color="inherit" />,
    component: AffiliateGuard(AffiliatedUserDataTable),
    noCollapse: true,
    affiliateType: true,
  },
  {
    type: "collapse",
    name: "Claimed business",
    key: "claimed-business",
    route: `${basePath}/claimed-business`,
    icon: <BiSolidBusiness size="15px" color="inherit" />,
    component: AuthGuard(claimedBusiness),
    noCollapse: true,
    affiliateType: false,
  },
  {
    type: "collapse",
    name: "Feed Preset",
    key: "feed-preset",
    route: `${basePath}/feed-preset`,
    icon: <MdCategory size="15px" color="inherit" />,
    component: AuthGuard(CatgoryTables),
    noCollapse: true,
    affiliateType: false,
  },

  {
    type: "collapse",
    name: "Bulk Import",
    key: "bulk-import",
    route: `${basePath}/bulk-import`,
    icon: <FaMailBulk size="15px" color="inherit" />,
    component: AuthGuard(AddBulkData),
    noCollapse: true,
    affiliateType: false,
  },
  {
    // type: "collapse",
    name: "Bulk Import",
    key: "bulk import",
    route: `${basePath}/bulk-import/:id`,
    icon: <FaMailBulk size="15px" color="inherit" />,
    component: AuthGuard(AddBulkDataID),
    noCollapse: true,
    affiliateType: false,
  },
  {
    // type: "collapse",
    name: "View Bulk Import",
    key: "view bulk import",
    route: `${basePath}/view-bulk-import/:id`,
    icon: <FaMailBulk size="15px" color="inherit" />,
    component: AuthGuard(viewBulkTable),
    noCollapse: true,
    affiliateType: false,
  },
  {
    type: "collapse",
    name: "Bulk Upload Action Status",
    key: "bulk-data",
    route: `${basePath}/bulk-data`,
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: AuthGuard(bulkTable),
    noCollapse: true,
    affiliateType: false,
  },
  {
    // type: "collapse",
    name: "Business Import Data Table",
    key: "business-import-data",
    route: `${basePath}/business-import-data`,
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: AuthGuard(BusinessImportDataTable),
    noCollapse: true,
    affiliateType: false,
  },
  {
    // type: "collapse",
    name: "Business Import Data Table",
    key: "business-import-data",
    route: `${basePath}/uploaded-business-import-data/:id`,
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: AuthGuard(UploadedBusinessImportDataTable),
    noCollapse: true,
    affiliateType: false,
  },
  {
    type: "collapse",
    name: "Business Import",
    key: "business-import",
    route: `${basePath}/business-import`,
    icon: <IoBusinessSharp size="15px" color="inherit" />,
    component: AuthGuard(BusinessImport),
    noCollapse: true,
    affiliateType: false,
  },

  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   route: `${basePath}/billing`,
  //   icon: <BsCreditCardFill size="15px" color="inherit" />,
  //   component: AuthGuard(Billing),
  //   noCollapse: true,
  //   affiliateType: false,
  // },

  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   route: `${basePath}/profile`,
  //   icon: <BsFillPersonFill size="15px" color="inherit" />,
  //   component: AffiliateGuard(Profile),
  //   noCollapse: true,
  //   affiliateType: true,
  // },
  {
    // type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: `${basePath}/signin`,
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: ProtectGuard(SignIn),
    noCollapse: true,
    affiliateType: false,
  },
  {
    // type: "collapse",
    name: "Dev",
    key: "dev",
    route: `${basePath}/signin-select`,
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: ProtectGuard(Dev),
    noCollapse: true,
    affiliateType: false,
  },
  {
    // type: "collapse",
    name: "affiliate Sign",
    key: "affiliate-Sign",
    route: `${basePath}/signin-affiliate`,
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: ProtectGuard(AffiliateSignIn),
    noCollapse: true,
    affiliateType: false,
  },
  {
    // type: "collapse",
    name: "Add User",
    key: "AddUser",
    route: `${basePath}/add-user`,
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: AuthGuard(AddUser),
    noCollapse: true,
    affiliateType: false,
  },
  {
    // type: "collapse",
    name: "Add User",
    key: "AddUser",
    route: `${basePath}/add-user/:id`,
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: AuthGuard(UpdateUser),
    noCollapse: true,
    affiliateType: false,
  },
  {
    // type: "collapse",
    name: "Affiliated Table",
    key: "AffiliatedTable",
    route: `${basePath}/affiliated-table/:id`,
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: AuthGuard(AffiliatedUserDataTableAdmin),
    noCollapse: true,
    affiliateType: false,
  },
  {
    // type: "collapse",
    name: "Add Preset",
    key: "AddPreset",
    route: `${basePath}/add-preset`,
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: AuthGuard(AddCategory),
    noCollapse: true,
    affiliateType: false,
  },

  {
    // type: "collapse",
    name: "Edit Preset",
    key: "EditPreset",
    route: `${basePath}/edit-preset/:id`,
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: AuthGuard(EditPreset),
    noCollapse: true,
    affiliateType: false,
  },
  {
    // type: "collapse",
    name: "User profile",
    key: "UserProfile",
    route: `${basePath}/profile/:id`,
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: AuthGuard(UserProfile),
    noCollapse: true,
    affiliateType: false,
  },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   route: "/authentication/sign-up",
  //   icon: <IoRocketSharp size="15px" color="inherit" />,
  //   component:AuthGuard( SignUp,
  //   noCollapse: true,
  // },
];

export default routes;
