import { createSlice } from "@reduxjs/toolkit";
const bulkDataTable = createSlice({
  name: "bulkDataTable",
  initialState: {
    bulkListing: {},
  },
  reducers: {
    setBulkDataTable: (state, action) => {
      state.bulkListing = action.payload; // Update the value in the store
    },
  },
});

export const { setBulkDataTable } = bulkDataTable.actions;
export default bulkDataTable.reducer;
