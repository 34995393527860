import { createSlice } from "@reduxjs/toolkit";
const profileInfo = createSlice({
  name: "profileInfo",
  initialState: {
    profileInfo: {},
  },
  reducers: {
    setprofileInfo: (state, action) => {
      console.log("123", state, action);
      state.profileInfo = action.payload; // Update the value in the store
    },
  },
});

export const { setprofileInfo } = profileInfo.actions;
export default profileInfo.reducer;
