import { Card, CircularProgress, Grid, Stack } from "@mui/material";

import React, { useEffect } from "react";

//=================Template imports================================

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";

// Icons
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";

//   React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

//   assets
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Page imports
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import { useDispatch } from "react-redux";
import { FormHelperText } from "@mui/material";
import { storeAddUser } from "store/api/auth";
import Toast from "helpers/Toast";
import { basePath } from "config";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { setMedia } from "store/api/auth";
const AddUserForm = ({ member, userData }) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState("");
  const [cover, setCover] = useState("");
  const [cover2, setCover2] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = "Add User";
  }, []);
  //   const history = useHistory();

  const onSubmit = async (values) => {
    setLoading(true);
    const body = { ...values, userType: values.userType ? 2 : 1, image, cover };

    if (body.email) delete body.contact;
    else delete body.email;

    console.log("body", body);

    dispatch(
      storeAddUser({
        body,
        onSuccess: (res) => {
          // history.push("/");
          history.goBack();
          Toast.success("User created successfuly", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setLoading(false);
        },

        onError: (error) => {
          Toast.error(error.response.data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setLoading(false);
        },
      })
    );
  };

  const initialValues = {
    name: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    userType: false,
    contact: "",
    bio: "",
    isBusiness: false,
    dateOfBirth: "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().required(" Required"),
    username: Yup.string().required("Required"),
    dateOfBirth: Yup.string().required(" Required"),
    // email: Yup.string().email("Invalid email format").required("Required"),
    email: Yup.string().email("Invalid email address"),
    contact: Yup.string().when("email", {
      is: (email) => !email,
      then: (schema) => schema.required("Email or Phone number is required"),
      otherwise: (schema) => schema.optional(),
    }),
    // password: Yup.string().required("Required"),
    // confirmPassword: Yup.string()
    //   .oneOf([Yup.ref("password"), null], "Passwords must match")
    //   .required("Required"),
  });
  const handleClickChangeAvatar = (e) => {
    e.preventDefault();
    document.getElementById("set-preset-icon").click();
  };
  function handleChangeAvatar(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
    // setImage();
    const image = e.target.files[0];
    console.log(image);
    const formData = new FormData();
    formData.append("image", image);
    dispatch(
      setMedia({
        body: formData,
        onSuccess: (res) => {
          // history.push("/");
          console.log("res", res);
          setImage(res.data.fileName);
          // Toast.success("User created successfuly", {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "dark",
          // });
          // setLoading(false);
        },

        onError: (error) => {
          console.log("error", error);
          Toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
  }
  const handleClickChangeCover = (e) => {
    e.preventDefault();
    document.getElementById("set-cover-icon").click();
  };
  function handleChangeCover(e) {
    setCover2(URL.createObjectURL(e.target.files[0]));
    const image = e.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    dispatch(
      setMedia({
        body: formData,
        onSuccess: (res) => {
          // history.push("/");
          console.log("res", res);
          setCover(res.data.fileName);
          // Toast.success("User created successfuly", {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "dark",
          // });
          // setLoading(false);
        },

        onError: (error) => {
          console.log("error", error);
          Toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
  }
  const formdata = () => {
    return (
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
            <>
              <VuiBox
                // component="form"
                // role="form"
                autoComplete="off"
                borderRadius="inherit"
                p="25px"
                sx={({ palette: { secondary } }) => ({
                  backgroundColor: secondary.focus,
                })}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                // maxWidth='60%'
              >
                {/* {loading ? (
                  <VuiBox
                    // container
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh"
                  >
                    <CircularProgress />
                  </VuiBox>
                ) : (
                  <> */}
                <div className="wrap-coverPhoto">
                  <div className="image-avatar-cover">
                    <div className="preset-icon22">
                      {file === null ? (
                        <></>
                      ) : (
                        <>
                          <img className="cover-bio" src={file === null ? logo : file} />
                        </>
                      )}
                    </div>
                    <input
                      id="set-preset-icon"
                      style={{ display: "none" }}
                      type="file"
                      // accept="image/jpeg, image/png, image/gif, image/jpg"
                      onChange={handleChangeAvatar}
                    />
                    <button onClick={handleClickChangeAvatar} className="btnChangeAvatar">
                      <span>Choose Avator</span>
                    </button>
                  </div>
                  <div className="image-avatar-cover">
                    <div className="preset-icon22">
                      {cover2 === null ? (
                        <></>
                      ) : (
                        <>
                          <img className="cover-bio" src={cover2 === null ? "" : cover2} />
                        </>
                      )}
                    </div>
                    <input
                      id="set-cover-icon"
                      style={{ display: "none" }}
                      type="file"
                      // accept="image/jpeg, image/png, image/gif, image/jpg"

                      onChange={handleChangeCover}
                    />
                    <button onClick={handleClickChangeCover} className="btnChangeAvatar">
                      <span>Choose Cover</span>
                    </button>
                  </div>
                </div>
                <VuiBox mb={2}>
                  <VuiBox mb={1} ml={0.5}>
                    <VuiTypography
                      component="label"
                      variant="button"
                      color="white"
                      fontWeight="medium"
                    >
                      Name
                    </VuiTypography>
                  </VuiBox>
                  <GradientBorder
                    minWidth="100%"
                    padding="1px"
                    borderRadius={borders.borderRadius.lg}
                    backgroundImage={radialGradient(
                      palette.gradients.borderLight.main,
                      palette.gradients.borderLight.state,
                      palette.gradients.borderLight.angle
                    )}
                  >
                    <VuiInput
                      type="text"
                      placeholder="Name..."
                      sx={({ typography: { size } }) => ({
                        fontSize: size.sm,
                      })}
                      fontWeight="500"
                      onChange={handleChange("name")}
                      onBlur={handleBlur("name")}
                      value={values.name}
                      // autoComplete={
                      error={errors.name && touched.name ? true : false}
                    />
                  </GradientBorder>
                  <FormHelperText error={errors.name && touched.name ? true : false}>
                    {errors.name && touched.name ? errors.name : null}
                  </FormHelperText>
                </VuiBox>

                <VuiBox mb={2}>
                  <VuiBox mb={1} ml={0.5}>
                    <VuiTypography
                      component="label"
                      variant="button"
                      color="white"
                      fontWeight="medium"
                    >
                      User Name
                    </VuiTypography>
                  </VuiBox>
                  <GradientBorder
                    minWidth="100%"
                    padding="1px"
                    borderRadius={borders.borderRadius.lg}
                    backgroundImage={radialGradient(
                      palette.gradients.borderLight.main,
                      palette.gradients.borderLight.state,
                      palette.gradients.borderLight.angle
                    )}
                  >
                    <VuiInput
                      type="text"
                      sx={({ typography: { size } }) => ({
                        fontSize: size.sm,
                      })}
                      placeholder="Your User Name..."
                      fontWeight="500"
                      onChange={handleChange("username")}
                      onBlur={handleBlur("username")}
                      value={values.username}
                      // autoComplete={false}
                      error={errors.username && touched.username ? true : false}
                      // helperText={errors.username && touched.username ? errors.username : null}
                    />
                  </GradientBorder>
                  <FormHelperText error={errors.username && touched.username ? true : false}>
                    {errors.username && touched.username ? errors.username : null}
                  </FormHelperText>
                </VuiBox>

                <VuiBox mb={2}>
                  <VuiBox mb={1} ml={0.5}>
                    <VuiTypography
                      component="label"
                      variant="button"
                      color="white"
                      fontWeight="medium"
                    >
                      Email
                    </VuiTypography>
                  </VuiBox>
                  <GradientBorder
                    minWidth="100%"
                    borderRadius={borders.borderRadius.lg}
                    padding="1px"
                    backgroundImage={radialGradient(
                      palette.gradients.borderLight.main,
                      palette.gradients.borderLight.state,
                      palette.gradients.borderLight.angle
                    )}
                  >
                    <VuiInput
                      type="email"
                      placeholder="Your email..."
                      sx={({ typography: { size } }) => ({
                        fontSize: size.sm,
                      })}
                      id="login-email"
                      name="email"
                      onChange={handleChange("email")}
                      onBlur={handleBlur("email")}
                      autoComplete="off"
                      disabled={values.contact}
                      value={values.email}
                      error={
                        errors.email && touched.email
                          ? true
                          : errors.contact &&
                            touched.contact &&
                            errors.contact === "Email or Phone number is required"
                          ? true
                          : false
                      }
                      // helperText={errors.email && touched.email ? errors.email : null}
                    />
                  </GradientBorder>
                  <FormHelperText
                    error={
                      errors.email && touched.email
                        ? true
                        : errors.contact &&
                          touched.contact &&
                          errors.contact === "Email or Phone number is required"
                        ? true
                        : false
                    }
                  >
                    {errors.email && touched.email
                      ? errors.email
                      : errors.contact &&
                        touched.contact &&
                        errors.contact === "Email or Phone number is required"
                      ? errors.contact
                      : false}
                  </FormHelperText>
                </VuiBox>
                {/* <VuiBox mb={2}>
                  <VuiBox mb={1} ml={0.5}>
                    <VuiTypography
                      component="label"
                      variant="button"
                      color="white"
                      fontWeight="medium"
                    >
                      Phone Number
                    </VuiTypography>
                  </VuiBox>
                  <GradientBorder
                    minWidth="100%"
                    borderRadius={borders.borderRadius.lg}
                    padding="1px"
                    backgroundImage={radialGradient(
                      palette.gradients.borderLight.main,
                      palette.gradients.borderLight.state,
                      palette.gradients.borderLight.angle
                    )}
                  >
                    <VuiInput
                      type="text"
                      placeholder="contact..."
                      sx={({ typography: { size } }) => ({
                        fontSize: size.sm,
                      })}
                      id="login-email"
                      name="contact"
                      disabled={values.email}
                      onChange={handleChange("contact")}
                      onBlur={handleBlur("contact")}
                      autoComplete="off"
                      value={values.contact}
                      error={errors.contact && touched.contact ? true : false}
                      // helperText={errors.email && touched.email ? errors.email : null}
                    />
                  </GradientBorder>
                  <FormHelperText error={errors.contact && touched.contact ? true : false}>
                    {errors.contact && touched.contact ? errors.contact : false}
                  </FormHelperText>
                </VuiBox> */}
                <VuiBox mb={2}>
                  <VuiBox mb={1} ml={0.5}>
                    <VuiTypography
                      component="label"
                      variant="button"
                      color="white"
                      fontWeight="medium"
                    >
                      Phone Number
                    </VuiTypography>
                  </VuiBox>
                  <GradientBorder
                    minWidth="100%"
                    borderRadius={borders.borderRadius.lg}
                    padding="1px"
                    backgroundImage={radialGradient(
                      palette.gradients.borderLight.main,
                      palette.gradients.borderLight.state,
                      palette.gradients.borderLight.angle
                    )}
                  >
                    {/* <VuiInput
                      type="text"
                      placeholder="contact..."
                      sx={({ typography: { size } }) => ({
                        fontSize: size.sm,
                      })}
                      id="login-email"
                      name="contact"
                      disabled={values.email} 
                      onChange={handleChange("contact")}
                      onBlur={handleBlur("contact")}
                      autoComplete="off"
                      value={values.contact}
                      error={errors.contact && touched.contact ? true : false}
                      // helperText={errors.email && touched.email ? errors.email : null}
                    /> */}
                    <PhoneInput
                      // {...field}
                      country={"us"} // Set default country
                      inputStyle={{ width: "100%" }}
                      placeholder="+1 1234567890"
                      name="contact"
                      disabled={values.email}
                      onChange={handleChange("contact")}
                      onBlur={handleBlur("contact")}
                      // autoComplete="off"
                      value={values.contact}
                      inputClass={`${
                        errors.contact && touched.contact
                          ? "my-custom-input-home"
                          : "my-custom-input-home2"
                      }`}
                    />
                  </GradientBorder>
                  <FormHelperText error={errors.contact && touched.contact ? true : false}>
                    {errors.contact && touched.contact ? errors.contact : false}
                  </FormHelperText>
                </VuiBox>
                <VuiBox mb={2}>
                  <VuiBox mb={1} ml={0.5}>
                    <VuiTypography
                      component="label"
                      variant="button"
                      color="white"
                      fontWeight="medium"
                    >
                      Date Of Birth
                    </VuiTypography>
                  </VuiBox>
                  <GradientBorder
                    minWidth="100%"
                    padding="1px"
                    borderRadius={borders.borderRadius.lg}
                    backgroundImage={radialGradient(
                      palette.gradients.borderLight.main,
                      palette.gradients.borderLight.state,
                      palette.gradients.borderLight.angle
                    )}
                  >
                    <VuiInput
                      type="date"
                      // placeholder="Name..."
                      sx={({ typography: { size } }) => ({
                        fontSize: size.sm,
                      })}
                      fontWeight="500"
                      onChange={handleChange("dateOfBirth")}
                      onBlur={handleBlur("dateOfBirth")}
                      value={values.dateOfBirth}
                      // autoComplete={
                      error={errors.dateOfBirth && touched.dateOfBirth ? true : false}
                    />
                  </GradientBorder>
                  <FormHelperText error={errors.dateOfBirth && touched.dateOfBirth ? true : false}>
                    {errors.dateOfBirth && touched.dateOfBirth ? errors.dateOfBirth : null}
                  </FormHelperText>
                </VuiBox>
                <VuiBox mb={2}>
                  <VuiBox mb={1} ml={0.5}>
                    <VuiTypography
                      component="label"
                      variant="button"
                      color="white"
                      fontWeight="medium"
                    >
                      Bio
                    </VuiTypography>
                  </VuiBox>
                  <GradientBorder
                    minWidth="100%"
                    padding="1px"
                    borderRadius={borders.borderRadius.lg}
                    backgroundImage={radialGradient(
                      palette.gradients.borderLight.main,
                      palette.gradients.borderLight.state,
                      palette.gradients.borderLight.angle
                    )}
                  >
                    <VuiInput
                      type="text"
                      placeholder="bio..."
                      sx={({ typography: { size } }) => ({
                        fontSize: size.sm,
                      })}
                      fontWeight="500"
                      onChange={handleChange("bio")}
                      onBlur={handleBlur("bio")}
                      value={values.bio}
                      // autoComplete={
                      error={errors.bio && touched.bio ? true : false}
                    />
                  </GradientBorder>
                  <FormHelperText error={errors.bio && touched.bio ? true : false}>
                    {errors.bio && touched.bio ? errors.bio : null}
                  </FormHelperText>
                </VuiBox>
                {values.userType ? (
                  <>
                    <VuiBox mb={2}>
                      <VuiBox mb={1} ml={0.5}>
                        <VuiTypography
                          component="label"
                          variant="button"
                          color="white"
                          fontWeight="medium"
                        >
                          Password
                        </VuiTypography>
                      </VuiBox>
                      <GradientBorder
                        minWidth="100%"
                        borderRadius={borders.borderRadius.lg}
                        padding="1px"
                        backgroundImage={radialGradient(
                          palette.gradients.borderLight.main,
                          palette.gradients.borderLight.state,
                          palette.gradients.borderLight.angle
                        )}
                      >
                        <VuiInput
                          type="password"
                          placeholder="Your password..."
                          sx={({ typography: { size } }) => ({
                            fontSize: size.sm,
                          })}
                          name="password"
                          onChange={handleChange("password")}
                          onBlur={handleBlur("password")}
                          autoComplete="off"
                          value={values.password}
                          error={errors.password && touched.password ? true : false}
                          // helperText={errors.password && touched.password ? errors.password : null}
                        />
                      </GradientBorder>
                      <FormHelperText error={errors.password && touched.password ? true : false}>
                        {errors.password && touched.password ? errors.password : false}
                      </FormHelperText>
                    </VuiBox>
                    <VuiBox mb={2}>
                      <VuiBox mb={1} ml={0.5}>
                        <VuiTypography
                          component="label"
                          variant="button"
                          color="white"
                          fontWeight="medium"
                        >
                          Confirm Password
                        </VuiTypography>
                      </VuiBox>
                      <GradientBorder
                        minWidth="100%"
                        borderRadius={borders.borderRadius.lg}
                        padding="1px"
                        backgroundImage={radialGradient(
                          palette.gradients.borderLight.main,
                          palette.gradients.borderLight.state,
                          palette.gradients.borderLight.angle
                        )}
                      >
                        <VuiInput
                          type="password"
                          placeholder="Confirm Password"
                          sx={({ typography: { size } }) => ({
                            fontSize: size.sm,
                          })}
                          name="confirmPassword"
                          onChange={handleChange("confirmPassword")}
                          onBlur={handleBlur("confirmPassword")}
                          autoComplete="off"
                          value={values.confirmPassword}
                          error={errors.confirmPassword && touched.confirmPassword ? true : false}
                          // helperText={
                          //   errors.confirmPassword && touched.confirmPassword
                          //     ? errors.confirmPassword
                          //     : null
                          // }
                        />
                      </GradientBorder>
                      <FormHelperText
                        error={errors.confirmPassword && touched.confirmPassword ? true : false}
                      >
                        {errors.confirmPassword && touched.confirmPassword
                          ? errors.confirmPassword
                          : null}
                      </FormHelperText>
                    </VuiBox>
                  </>
                ) : null}

                <Grid item xs={12} md={6} xl={3}>
                  <VuiSwitch
                    color="info"
                    checked={values.userType}
                    name="userType"
                    onChange={handleChange("userType")}
                  />
                  <VuiTypography variant="lg" color="white" fontWeight="medium" ml="6px" mb="6px">
                    Is Admin
                  </VuiTypography>
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <VuiSwitch
                    color="info"
                    checked={values.isBusiness}
                    name="isBusiness"
                    onChange={handleChange("isBusiness")}
                  />
                  <VuiTypography variant="lg" color="white" fontWeight="medium" ml="6px" mb="6px">
                    Is Business
                  </VuiTypography>
                </Grid>
                {/* </>
                )} */}

                <VuiBox mt={4} mb={1}>
                  <VuiButton
                    disabled={loading === true ? true : false}
                    color="info"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {loading ? <CircularProgress size={20} /> : " Submit"}
                  </VuiButton>
                </VuiBox>
              </VuiBox>
            </>
          )}
        </Formik>
      </>
    );
  };

  return (
    <Card
    // sx={({ breakpoints }) => ({
    //   [breakpoints.up("xxl")]: {
    //     maxHeight: "400px",
    //   },
    // })}
    >
      <VuiBox display="flex" flexDirection="column">
        <VuiTypography variant="lg" color="white" fontWeight="bold" mb="6px">
          Add {member}
        </VuiTypography>
        <VuiTypography variant="button" color="text" fontWeight="regular" mb="30px">
          Let's increase our community
        </VuiTypography>

        <VuiBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          sx={({ breakpoints }) => ({
            [breakpoints.only("sm")]: {
              alignItems: "center",
            },
          })}
          alignItems="center"
        >
          <GradientBorder borderRadius={borders.borderRadius.form} minWidth="70%" maxWidth="80%">
            <VuiBox
              component="form"
              role="form"
              borderRadius="inherit"
              sx={({ palette: { secondary } }) => ({
                backgroundColor: secondary.focus,
              })}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              {formdata()}
            </VuiBox>
          </GradientBorder>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default AddUserForm;
