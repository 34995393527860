import { createSlice } from "@reduxjs/toolkit";
const claimedBusiness = createSlice({
  name: "claimedBusiness",
  initialState: {
    claimedBusinessListing: [],
  },
  reducers: {
    setClaimedBusiness: (state, action) => {
      state.claimedBusinessListing = action.payload; // Update the value in the store
    },
  },
});

export const { setClaimedBusiness } = claimedBusiness.actions;
export default claimedBusiness.reducer;
