import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import EditPresetForm from "./components/editpreset";

const EditPreset = () => {
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <VuiBox py={3}>
          <EditPresetForm member="Preset" />
        </VuiBox>
      </DashboardLayout>
    </>
  );
};

export default EditPreset;
