import http from "../../services/http";
import storage from "../../services/storage";
// import { handleErrors } from "../../helpers/error";

export const newUser = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.post({
        url: "/analytics/new_users",
        body,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const ClaimedUser = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.post({
        url: "/analytics/business_claimed",
        body,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const usersPostsByDay = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.post({
        url: "/analytics/users_posts_by_day",
        body,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const newUsersToday = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.post({
        url: "/analytics/new_users_today",
        body,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const newPostToday = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.post({
        url: "/analytics/new_posts_today",

        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};

export const PostsByDayFilter = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.post({
        url: "/analytics/users_media_by_day",
        body,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const PostsByDayOnCreated = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.post({
        url: "/analytics/media_by_day",
        body,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const ActiveUserNow = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        url: "/analytics/active_users_now",

        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const TotalPost = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        url: "/analytics/total_posts",

        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const ActiveUsersByDay = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.post({
        url: "/analytics/active_users_by_day",
        body,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const TotalUserProfileType = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.post({
        url: "/analytics/user_profile_types",

        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
