import { createSlice } from "@reduxjs/toolkit";
const viewUploadedBusinessDataTable = createSlice({
  name: "viewUploadedBusinessDataTable",
  initialState: {
    viewUploadedBusinessDataListing: {},
  },
  reducers: {
    setViewUploadedBusinessDataTable: (state, action) => {
      state.viewUploadedBusinessDataListing = action.payload; // Update the value in the store
    },
  },
});

export const { setViewUploadedBusinessDataTable } = viewUploadedBusinessDataTable.actions;
export default viewUploadedBusinessDataTable.reducer;
