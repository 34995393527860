import axios from "axios";
import { bulk } from "../config";
import storage from "./storage";

const post = ({ url = "", body = {}, headers = {}, queryParams = {}, otherConfigs = {} }) => {
  return axios.post(bulk + url, body, {
    headers,
    params: queryParams,
    ...otherConfigs,
  });
};

export default {
  post,
};
