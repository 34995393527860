/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// custom styles for the NotificationItem
import { menuItem, menuImage } from "examples/Items/NotificationItem/styles";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router-dom";
import { basePath } from "config";

// import Icon from "@mui/material/Icon";
const NotificationItem = forwardRef(({ color, image, title, date, ...rest }, ref) => (
  <MenuItem
    {...rest}
    ref={ref}
    sx={(theme) => menuItem(theme)}
    onClick={() => {
      localStorage.removeItem("adminAuthToken");
      localStorage.removeItem("adminUser");
      localStorage.removeItem("baseUrlPath");
      localStorage.removeItem("affiliateAuthToken");

      // useHistory().push(basePath + "/signin");
      location.reload();
    }}
  >
    {/* <VuiBox
      width="2.25rem"
      height="2.25rem"
      mt={0.25}
      mr={2}
      mb={0.25}
      borderRadius="lg"
      sx={(theme) => menuImage(theme, { color })}
    >
      {image}
    </VuiBox> */}
    {/* <VuiBox>
      <VuiTypography variant="button" textTransform="capitalize" fontWeight="regular">
        <strong>{title[0]}</strong> {title[1]}
      </VuiTypography>
      <VuiTypography
        variant="caption"
        color="text"
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 0.5,
        }}
      >
        <VuiTypography variant="button" color="text">
          <Icon
            sx={{
              lineHeight: 1.2,
              mr: 0.5,
            }}
          >
            watch_later
          </Icon>
        </VuiTypography>
        {date}
      </VuiTypography>
    </VuiBox> */}

    <IconButton
      size="small"
      color="inherit"
      // sx={navbarIconButton}
      // aria-controls="notification-menu"
      aria-haspopup="true"
      variant="contained"
    >
      <Icon className={"text-white"}>logout</Icon>
      <p style={{ marginLeft: "11%" }}>logout</p>
    </IconButton>
  </MenuItem>
));

// Setting default values for the props of NotificationItem
NotificationItem.defaultProps = {
  color: "dark",
};

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "text",
  ]),
  image: PropTypes.node.isRequired,
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
  date: PropTypes.string.isRequired,
};

export default NotificationItem;
