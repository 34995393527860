import { createSlice } from "@reduxjs/toolkit";
const categoryDataTable = createSlice({
  name: "categoryDataTable",
  initialState: {
    categoryListing: {},
    presetIcon: {},
  },
  reducers: {
    setCategoryDataTable: (state, action) => {
      state.categoryListing = action.payload; // Update the value in the store
    },
    setPresetIcon: (state, action) => {
      state.presetIcon = action.payload; // Update the value in the store
    },

    removeCategoryFromTable: (state, action) => {
      const id = action.payload;
      state.categoryListing.data = state.categoryListing.data.filter(({ _id }) => _id !== id);
    },
  },
});

export const { setCategoryDataTable, removeCategoryFromTable, setPresetIcon } =
  categoryDataTable.actions;
export default categoryDataTable.reducer;
