import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import Table from "examples/Tables/Table";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
// import DataTable from "react-data-table-component";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getUserTable } from "store/api/userTable";
// import VipTableData from "./data/VipTableData";
import { CircularProgress, FormControl, MenuItem, Pagination, Select } from "@mui/material";
// import VuiInput from "components/VuiInput";
// import VuiButton from "components/VuiButton";
// import { Link } from "react-router-dom";
// import { getvipTable } from "store/api/userTable";
// import { getGlobalCouponLimit } from "store/api/userTable";
// import { postGlobalCouponLimit } from "store/api/userTable";
import Toast from "helpers/Toast";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import VuiButton from "components/VuiButton";
import csv from "assets/images/csv3.png";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
// import { ProgressBar } from "@ramonak/react-progress-bar";
import { useHistory } from "react-router-dom";

import ProgressBar from "@ramonak/react-progress-bar";
import { basePath } from "config";
import { SiSoundcloud } from "react-icons/si";
import { PiTiktokLogoFill } from "react-icons/pi";
import { IoLogoYoutube } from "react-icons/io";
import { FaSquareInstagram } from "react-icons/fa6";
import VuiInput from "components/VuiInput";
import { getUserTable } from "store/api/userTable";
import VuiAvatar from "components/VuiAvatar";
import { sendBulkData } from "store/api/bulk";
import { Link } from "react-router-dom";
import profile from "assets/images/profile.png";
import { GrUserNew } from "react-icons/gr";
import { FaClipboardUser } from "react-icons/fa6";
import { MdSignpost } from "react-icons/md";
import { BsFillSignpostSplitFill } from "react-icons/bs";
import { ImProfile } from "react-icons/im";
import { IoBusiness } from "react-icons/io5";
import { GiShadowFollower } from "react-icons/gi";
import { RiUserFollowFill } from "react-icons/ri";
import { BiSolidLike } from "react-icons/bi";
import { SiStatuspage } from "react-icons/si";
import { BsCalendarDateFill } from "react-icons/bs";
import post1 from "assets/images/post1.jpeg";
import post2 from "assets/images/post2.jpeg";
import post3 from "assets/images/post3.png";
import post4 from "assets/images/post4.png";
import typ1 from "assets/images/format_audio.png";
import typ2 from "assets/images/format_image.png";
import typ3 from "assets/images/format_text.png";
import typ4 from "assets/images/format_video.png";
import { useParams } from "react-router-dom";
import UserContentTable from "./data/UserContentTable";
import { userProfileInfo } from "store/api/bulk";
import { ProfileContent } from "store/api/bulk";
import storage from "services/storage";

const selectPlatformType = [
  {
    type: typ1,
    img: post1,
  },
  {
    type: typ2,
    img: post3,
  },
  {
    type: typ3,
    img: post4,
  },
  {
    type: typ4,
    img: post2,
  },
  {
    type: typ2,
    img: post4,
  },
];

function UserProfile() {
  const path = storage.get("baseUrlPath");
  console.log("path", path);
  const columns2 = [
    { header_name: "Timestamp", align: "left", minWidth: 100 },
    { header_name: "Thumbnail", align: "center", minWidth: 229 },
    { header_name: "Content Title", align: "center", minWidth: 229 },
    { header_name: "Content Description ", align: "center", minWidth: 229 },
    { header_name: "Content Format", align: "center", minWidth: 229 },
    { header_name: "Tags", align: "center", minWidth: 200 },
    // { header_name: "Project", align: "center", minWidth: 200 },
    { header_name: "Collaborators", align: "center", minWidth: 200 },
    { header_name: "Link ", align: "center", minWidth: 200 },
    { header_name: "Action", align: "center", minWidth: 200 },
  ];
  const { id } = useParams();
  console.log("id===", id);
  const history = useHistory();
  const dispatch = useDispatch();
  const { profileInfo } = useSelector((state) => state.profileInfo);
  const { contentUserListing } = useSelector((state) => state.contentDataTable);

  useEffect(() => {
    setloading(true);
    dispatch(
      userProfileInfo({
        id,
        onSuccess: (res) => {
          console.log("res", res);
          setloading(false);
        },
        onError: (error) => {
          setloading(false);

          console.log("error", error);
        },
      })
    );
  }, []);
  const [fakeData, setFakeData] = useState([
    {
      timeStamp: "3-20-2023",
      thumbnail: "The FireWorks",
      contentTitle: "The FireWorks",
      contentDescription: "The FireWorks ",
      contentFormat: "image",
      tags: "tree, fire, ",
      project: "-",
      collaborators: "Tree User",
      link: "http://dev.tree.art/post/65716fbd79dd0c5b6498bb48",
    },
    {
      timeStamp: "3-20-2023",
      thumbnail: "Ship is ready to set sail",
      contentTitle: "Ship is ready to set sail",
      contentDescription: "Ship is ready to set sail",
      contentFormat: "video ",
      tags: "ship, sail",
      project: "-",
      collaborators: "Tree User",
      link: "http://dev.tree.art/post/657c4ec32ea1940ab898fa3e",
    },
    {
      timeStamp: "3-20-2023",
      thumbnail: "White cat",
      contentTitle: "White cat",
      contentDescription: "White cat",
      contentFormat: "image",
      tags: "cat",
      project: "-",
      collaborators: "Tree User",
      link: "http://dev.tree.art/post/65329be861599a52979dc7ea",
    },
  ]);
  const [loading, setloading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const [data, setData] = useState([]);

  const [pageNumber, setpageNumber] = useState(1);
  const [search, setSearch] = useState("");
  const [pg, setPg] = useState(0);
  const [pageSize, setpageSize] = useState("10");
  useEffect(() => {
    setLoading2(true);

    dispatch(
      ProfileContent({
        id,
        pageNumber,
        pageSize,

        onSuccess: (res) => {
          console.log("res,,", res);

          setLoading2(false);
          setData(res.data.data);
          setPg(Math.ceil(res.data.recordsFiltered / Number(pageSize)));
        },
      })
    );
  }, [pageSize, pageNumber]);
  const handlePageChange = (event, page) => {
    setpageNumber(page);
    document.documentElement.scrollTop = 0;
  };
  const handleChange = (event) => {
    setpageSize(event.target.value);
    setpageNumber(1);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        {loading ? (
          <>
            <VuiBox
              // container
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="60vh"
            >
              <CircularProgress />
            </VuiBox>
          </>
        ) : (
          <VuiBox mb={0} sx={{ minHeight: "74vh" }}>
            <Card>
              <VuiBox mb="22px">
                <div className="profile-details">
                  <div className="wrap-user-profile-image">
                    <div className="user-profile-image">
                      <img src={profileInfo.image} />
                    </div>
                    <div className="user-information">
                      <h1>{profileInfo.name} </h1>
                      <h1>{profileInfo.username}</h1>
                      <h1> {profileInfo.contact === null ? "" : profileInfo.contact}</h1>
                      {path === "dev" ? (
                        <a
                          target="_blank"
                          href={`http://dev.tree.art/profile/${profileInfo.username}`}
                        >
                          Profile link
                        </a>
                      ) : (
                        <a
                          target="_blank"
                          href={`https://tree.art/profile/${profileInfo.username}`}
                        >
                          Profile link
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </VuiBox>
              <VuiBox mb={3}>
                {/* <h1>User Activity</h1> */}
                <VuiTypography variant="lg" color="white">
                  User Activity
                </VuiTypography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} xl={3}>
                    <MiniStatisticsCard
                      paddingSize="12px"
                      // paddingSize="12px"
                      title={{ text: "Last Active", fontWeight: "regular" }}
                      count={profileInfo.lastActive}
                      icon={{ color: "info", component: <GrUserNew size="22px" color="white" /> }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={3}>
                    <MiniStatisticsCard
                      paddingSize="12px"
                      title={{ text: "Followers " }}
                      count={profileInfo.followers === null ? "0" : profileInfo.followers}
                      // percentage={{ color: "success" }}
                      icon={{
                        color: "info",
                        component: <GiShadowFollower size="22px" color="white" />,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={3}>
                    <MiniStatisticsCard
                      paddingSize="12px"
                      title={{ text: "Following " }}
                      count={profileInfo.following === null ? "0" : profileInfo.following}
                      // percentage={{ color: "success" }}
                      icon={{
                        color: "info",
                        component: <RiUserFollowFill size="22px" color="white" />,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={3}>
                    <MiniStatisticsCard
                      paddingSize="12px"
                      title={{ text: "Total Likes " }}
                      count={profileInfo.totalLikes === null ? "0" : profileInfo.totalLikes}
                      // percentage={{ color: "success" }}
                      icon={{ color: "info", component: <BiSolidLike size="22px" color="white" /> }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={3}>
                    <MiniStatisticsCard
                      paddingSize="12px"
                      title={{ text: "Total Content Posted" }}
                      count={
                        profileInfo.totalContentPosted === null
                          ? "0"
                          : profileInfo.totalContentPosted
                      }
                      icon={{ color: "info", component: <MdSignpost size="22px" color="white" /> }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={6} xl={3}>
                        <MiniStatisticsCard
                        paddingSize="12px"
                          title={{ text: "Total Posts on Tree " }}
                          count={"123"}
                          // percentage={{ color: "success" }}
                          icon={{
                            color: "info",
                            component: <BsFillSignpostSplitFill size="22px" color="white" />,
                          }}
                        />
                      </Grid> */}
                </Grid>
              </VuiBox>
              <VuiBox mb={3}>
                <VuiTypography variant="lg" color="white">
                  User Information
                </VuiTypography>
                <Grid container spacing={3}>
                  {/* <Grid item xs={12} md={6} xl={3}>
                        <MiniStatisticsCard
                          paddingSize="12px"
                          title={{ text: "Sign On Method", fontWeight: "regular" }}
                          count={"Google"}
                          icon={{ color: "info", component: <GrUserNew size="22px" color="white" /> }}
                        />
                      </Grid> */}
                  <Grid item xs={12} md={6} xl={3}>
                    <MiniStatisticsCard
                      paddingSize="12px"
                      title={{ text: "Profile " }}
                      count={profileInfo.isBusiness === null ? "Personal" : "Business"}
                      // percentage={{ color: "success" }}
                      icon={{
                        color: "info",
                        component: <FaClipboardUser size="22px" color="white" />,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={3}>
                    <MiniStatisticsCard
                      paddingSize="12px"
                      title={{ text: "Status " }}
                      // count={"Active"}
                      count={profileInfo.isOnline === null ? "Non Active" : "Active"}
                      // percentage={{ color: "success" }}
                      icon={{
                        color: "info",
                        component: <SiStatuspage size="22px" color="white" />,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={3}>
                    <MiniStatisticsCard
                      paddingSize="12px"
                      title={{ text: "Joining Date " }}
                      count={new Date(profileInfo.createdAt).toLocaleDateString()}
                      // percentage={{ color: "success" }}
                      icon={{
                        color: "info",
                        component: <BsCalendarDateFill size="22px" color="white" />,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={3}>
                    <MiniStatisticsCard
                      paddingSize="12px"
                      title={{ text: "Date of Birth " }}
                      count={
                        profileInfo.dateOfBirth === null
                          ? "---"
                          : new Date(profileInfo.dateOfBirth).toLocaleDateString()
                      }
                      icon={{
                        color: "info",
                        component: <BsCalendarDateFill size="22px" color="white" />,
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={6} xl={3}>
                        <MiniStatisticsCard
                          paddingSize="12px"
                          title={{ text: "Total Posts on Tree " }}
                          count={"123"}
                          // percentage={{ color: "success" }}
                          icon={{
                            color: "info",
                            component: <BsFillSignpostSplitFill size="22px" color="white" />,
                          }}
                        />
                      </Grid> */}
                </Grid>
              </VuiBox>
              <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
                <VuiBox>
                  <VuiTypography variant="lg" color="white">
                    Content Posted
                  </VuiTypography>
                </VuiBox>
                <VuiBox mr={2}>
                  <VuiTypography mr={2} variant="overline" color="white">
                    Items Per Page
                  </VuiTypography>
                  <FormControl
                    variant="outlined"
                    size="small"
                    sx={{
                      ".MuiInputBase-formControl": {
                        backgroundColor: "transparent !important",
                        color: "white !important",
                        border: "1px solid white !important",
                      },
                    }}
                  >
                    {/* <InputLabel sx={{color: 'white !important'}}>Per Page</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={pageSize}
                      label="Per Page Items"
                      onChange={handleChange}
                      sx={{
                        ".MuiInputBase-root-outlined": {
                          backgroundColor: "transparent !important",
                        },
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      {/* <MenuItem value={100}>100</MenuItem> */}
                    </Select>
                  </FormControl>
                </VuiBox>
                <VuiBox mr={2}>
                  <VuiButton color="info" component={Link} to={`${basePath}/bulk-import/${id}`}>
                    <VuiTypography variant="button" color="white" fontWeight="regular">
                      Bulk Import
                    </VuiTypography>
                  </VuiButton>
                </VuiBox>
              </VuiBox>
              <VuiBox mb={3}>
                <VuiBox
                  sx={{
                    "& th": {
                      borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                        `${borderWidth[1]} solid ${grey[700]}`,
                    },
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        backgroundColor: "red",
                        borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                          `${borderWidth[1]} solid ${grey[700]}`,
                      },
                    },
                    "& .MuiTableRow-root td": {
                      backgroundColor: "transparent !important",
                      color: "white !important",
                    },
                  }}
                >
                  {/* <Table columns={columns} rows={rows} /> */}
                  {loading2 ? (
                    <>
                      <VuiBox
                        // container
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="60vh"
                      >
                        <CircularProgress />
                      </VuiBox>
                    </>
                  ) : (
                    <>
                      <UserContentTable rows={contentUserListing?.data?.data} columns={columns2} />
                      <VuiBox display="flex" justifyContent="center" alignItems="center">
                        <Pagination
                          count={Math.ceil(contentUserListing?.data?.count / Number(pageSize))}
                          page={pageNumber}
                          onChange={handlePageChange}
                          sx={{
                            "& .MuiPaginationItem-root": {
                              borderRadius: 8, // Customize the border-radius of each pagination item
                              backgroundColor: " #2f37ac", // Customize the background color of the selected pagination item
                              color: "#fff",
                            },
                            "& .Mui-selected": {
                              backgroundColor: "#0075ff", // Customize the background color of the selected pagination item
                              color: "#fff", // Customize the text color of the selected pagination item
                            },
                          }}
                        />
                      </VuiBox>
                      {/* <UserContentTable rows={contentUserListing} columns={columns2} />
                      <VuiBox display="flex" justifyContent="center" alignItems="center">
                        <Pagination
                          count={Math.ceil(contentUserListing?.count / Number(pageSize))}
                          page={pageNumber}
                          onChange={handlePageChange}
                          sx={{
                            "& .MuiPaginationItem-root": {
                              borderRadius: 8, // Customize the border-radius of each pagination item
                              backgroundColor: " #2f37ac", // Customize the background color of the selected pagination item
                              color: "#fff",
                            },
                            "& .Mui-selected": {
                              backgroundColor: "#27b9f2", // Customize the background color of the selected pagination item
                              color: "#fff", // Customize the text color of the selected pagination item
                            },
                          }}
                        />
                      </VuiBox> */}
                    </>
                  )}
                </VuiBox>
              </VuiBox>
            </Card>
          </VuiBox>
        )}
      </VuiBox>

      <Footer />
    </DashboardLayout>
  );
}

export default UserProfile;
