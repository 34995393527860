import { createSlice } from "@reduxjs/toolkit";
const affiliateDataTable = createSlice({
  name: "affiliateDataTable",
  initialState: {
    AffiliateListing: {},
    AffiliatedUserListing: {},
  },
  reducers: {
    setAffiliateDataTable: (state, action) => {
      state.AffiliateListing = action.payload; // Update the value in the store
    },
    setAffiliatedUserTable: (state, action) => {
      state.AffiliatedUserListing = action.payload; // Update the value in the store
    },
  },
});

export const { setAffiliateDataTable, setAffiliatedUserTable } = affiliateDataTable.actions;
export default affiliateDataTable.reducer;
