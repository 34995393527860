import Card from "@mui/material/Card";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserTable } from "store/api/userTable";
import BulkTableData from "./data/BulkTableData";
import { CircularProgress, FormControl, MenuItem, Pagination, Select } from "@mui/material";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import { Link } from "react-router-dom";
import { basePath } from "config";
import { getCategoryTable } from "store/api/category";
import { bulkSearch } from "store/api/bulk";

function AddBulkData() {
  const dispatch = useDispatch();
  const { bulkListing } = useSelector((state) => state.bulkDataTable);
  console.log("bulkListing", bulkListing);
  const { columns, rows } = authorsTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;
  const [data, setData] = useState([]);
  const [demoData, setDemoData] = useState([
    {
      csvName: "tree csv 1",
      // description: "Bengal tiger",
      admin: "tree admin",
      date: "20-01-2024 ",
    },
    {
      csvName: "tree csv 2",
      admin: "tree admin",
      date: "21-01-2024 ",
    },
    {
      csvName: "tree csv 3",
      admin: "tree admin",
      date: "22-01-2024 ",
    },
    {
      csvName: "tree csv 4",
      admin: "tree admin",
      date: "23-01-2024 ",
    },
    {
      csvName: "tree csv 5",
      admin: "tree admin",
      date: "24-01-2024 ",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchText, setSearchText] = useState("");
  // const [draw, setDraw] = useState("1");
  const [pageNumber, setpageNumber] = useState(1);
  const [search, setSearch] = useState("");
  const [pg, setPg] = useState(0);
  const [pageSize, setpageSize] = useState("10");

  useEffect(() => {
    setLoading(true);

    dispatch(
      bulkSearch({
        pageNumber,
        pageSize,

        onSuccess: (res) => {
          console.log("res,,", res);
          setLoading(false);
          setData(res.data.data);
          setPg(Math.ceil(res.data.recordsFiltered / Number(pageSize)));
        },
      })
    );
  }, [pageSize, pageNumber]);
  const columns2 = [
    { header_name: "User Detail", align: "left" },
    { header_name: "Platform", align: "center" },
    { header_name: "content ", align: "center" },

    { header_name: "Status", align: "center" },
    { header_name: "Admin", align: "center" },
    { header_name: "Date", align: "center" },
    { header_name: "Action", align: "center" },
  ];
  const handleInputChange = (event) => {
    // if (event.key === "Enter") {
    setSearch(event.target.value);
    setpageNumber(1);
    // }
  };
  // const filteredData = data.filter((item) =>
  //   item.name?.toLowerCase().includes(searchText?.toLowerCase())
  // );
  const handlePageChange = (event, page) => {
    setpageNumber(page);
    document.documentElement.scrollTop = 0;
  };
  const handleChange = (event) => {
    setpageSize(event.target.value);
    setpageNumber(1);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        {/* <div>
          <input
            type="text"
            placeholder="Search by name..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {loading ? (
            <div>Loading...</div>
          ) : error ? (
            <div>Error: {error.message}</div>
          ) : (
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              paginationPerPage={9} // Items per page
              paginationRowsPerPageOptions={[10, 20, 30]} // Selectable items per page
              paginationTotalRows={filteredData.pageSize} // Total rows for pagination
            />
          )}
        </div> */}

        <VuiBox mb={3}>
          <Card>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
              <VuiTypography variant="lg" color="white">
                Bulk Data table
              </VuiTypography>

              <VuiBox mr={2}>
                <VuiTypography mr={2} variant="overline" color="white">
                  Items Per Page
                </VuiTypography>
                <FormControl
                  variant="outlined"
                  size="small"
                  sx={{
                    ".MuiInputBase-formControl": {
                      backgroundColor: "transparent !important",
                      color: "white !important",
                      border: "1px solid white !important",
                    },
                  }}
                >
                  {/* <InputLabel sx={{color: 'white !important'}}>Per Page</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={pageSize}
                    label="Per Page Items"
                    onChange={handleChange}
                    sx={{
                      ".MuiInputBase-root-outlined": {
                        backgroundColor: "transparent !important",
                      },
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
              </VuiBox>
            </VuiBox>
            <VuiBox
              sx={{
                "& th": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    backgroundColor: "red",
                    borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                      `${borderWidth[1]} solid ${grey[700]}`,
                  },
                },
                "& .MuiTableRow-root td": {
                  backgroundColor: "transparent !important",
                  color: "white !important",
                },
              }}
            >
              {/* <Table columns={columns} rows={rows} /> */}
              {loading ? (
                <>
                  <VuiBox
                    // container
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="60vh"
                  >
                    <CircularProgress />
                  </VuiBox>
                </>
              ) : (
                <>
                  <BulkTableData rows={bulkListing.data} columns={columns2} />
                  <VuiBox display="flex" justifyContent="center" alignItems="center">
                    <Pagination
                      count={Math.ceil(bulkListing.count / Number(pageSize))}
                      page={pageNumber}
                      onChange={handlePageChange}
                      sx={{
                        "& .MuiPaginationItem-root": {
                          borderRadius: 8, // Customize the border-radius of each pagination item
                          backgroundColor: " #2f37ac", // Customize the background color of the selected pagination item
                          color: "#fff",
                        },
                        "& .Mui-selected": {
                          backgroundColor: "#0075ff", // Customize the background color of the selected pagination item
                          color: "#fff", // Customize the text color of the selected pagination item
                        },
                      }}
                    />
                  </VuiBox>
                </>
              )}
            </VuiBox>
          </Card>
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddBulkData;
