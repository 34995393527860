import { setPresetIcon } from "store/ui/category";
import http from "../../services/http";
import storage from "../../services/storage";
import { setCategoryDataTable } from "store/ui/category";

export const getCategoryTable = ({ onSuccess, pageNumber, pageSize, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        url: `/presets/admin?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);

      dispatch(setCategoryDataTable(res?.data));
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const deleteCategory = ({ body, onSuccess, userId, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.delete({
        url: "/presets/admin/" + userId,

        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};

export const getPresetByID = ({ body, onSuccess, id, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        url: "/presets/admin/" + id,

        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const updatePresetByID = ({ body, onSuccess, id, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.put({
        url: "/presets/admin/" + id,
        body,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const postPreset = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.post({
        url: `/presets/admin`,
        body,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const getPresetIcon = ({ onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        url: `/presets/preset_images`,

        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      dispatch(setPresetIcon(res?.data));
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
