import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserTable } from "store/api/userTable";
import VipTableData from "./data/VipTableData";
import { CircularProgress, FormControl, MenuItem, Pagination, Select } from "@mui/material";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import { Link } from "react-router-dom";
import { getvipTable } from "store/api/userTable";
import { getGlobalCouponLimit } from "store/api/userTable";
import { postGlobalCouponLimit } from "store/api/userTable";
import Toast from "helpers/Toast";

function Tables() {
  const dispatch = useDispatch();
  const { vipListing, couponLimit } = useSelector((state) => state.vipDataTable);
  const { columns, rows } = authorsTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [draw, setDraw] = useState("1");
  const [pages, setpages] = useState(1);
  const [search, setSearch] = useState("");
  const [pg, setPg] = useState(0);
  const [length, setLength] = useState("10");
  const [globalCouponLimit, setGlobalCouponLimit] = useState();

  useEffect(() => {
    setLoading(true);
    const start = (pages - 1) * length;

    dispatch(
      getvipTable({
        draw,
        search,
        length,
        start,
        onSuccess: (res) => {
          setLoading(false);
          setData(res.data.data);
          setPg(Math.ceil(res.data.recordsFiltered / Number(length)));
        },
      })
    );
  }, [draw, length, search, pg, pages]);
  useEffect(() => {
    dispatch(
      getGlobalCouponLimit({
        onSuccess: (res) => {
          setGlobalCouponLimit(res?.data.globalLimit);
        },
      })
    );
  }, []);
  const columns2 = [
    { header_name: "User detail", align: "left" },
    { header_name: "Status", align: "center" },
    { header_name: "Action", align: "center" },
    { header_name: "Limit", align: "left" },
  ];
  const handleInputChange = (event) => {
    // if (event.key === "Enter") {
    setSearch(event.target.value);
    setpages(1);
    // }
  };
  const filteredData = data.filter((item) =>
    item.name?.toLowerCase().includes(searchText?.toLowerCase())
  );
  const handlePageChange = (event, page) => {
    setpages(page);
    document.documentElement.scrollTop = 0;
  };
  const handleChange = (event) => {
    setLength(event.target.value);
    setpages(1);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Card>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
              <VuiTypography variant="lg" color="white">
                Vips table
              </VuiTypography>

              <VuiBox mr={2}>
                <VuiTypography mr={2} variant="overline" color="white">
                  Items Per Page
                </VuiTypography>
                <FormControl
                  variant="outlined"
                  size="small"
                  sx={{
                    ".MuiInputBase-formControl": {
                      backgroundColor: "transparent !important",
                      color: "white !important",
                      border: "1px solid white !important",
                    },
                  }}
                >
                  {/* <InputLabel sx={{color: 'white !important'}}>Per Page</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={length}
                    label="Per Page Items"
                    onChange={handleChange}
                    sx={{
                      ".MuiInputBase-root-outlined": {
                        backgroundColor: "transparent !important",
                      },
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
              </VuiBox>
              <VuiBox mr={2} display="flex">
                <VuiTypography mr={2} variant="overline" color="white">
                  Global Coupon Limit
                </VuiTypography>
                <VuiInput
                  value={globalCouponLimit}
                  //   key={row?._id}
                  // value={localLimit}
                  onChange={(e) => {
                    setGlobalCouponLimit(e.target.value);
                  }}
                  onKeyDown={async (e) => {
                    if (e.key === "Enter") {
                      dispatch(
                        postGlobalCouponLimit({
                          body: {
                            couponLimit: globalCouponLimit,
                          },
                          onSuccess: (res) => {
                            Toast.success(res.data, {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "dark",
                            });
                          },
                          onError: (error) => {
                            Toast.error("Something went wrong", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "dark",
                            });
                          },
                        })
                      );
                    }
                  }}
                  sx={{
                    maxWidth: "50px",
                  }}
                />
              </VuiBox>
              <VuiInput
                id="search-input"
                name="search-input"
                placeholder="Type here..."
                onChange={handleInputChange}
                // onKeyPress={handleKeyPress}
                icon={{ component: "search", direction: "left" }}
                sx={({ breakpoints }) => ({
                  [breakpoints.down("sm")]: {
                    maxWidth: "80px",
                  },
                  [breakpoints.only("sm")]: {
                    maxWidth: "80px",
                  },
                  backgroundColor: "info.main !important",
                })}
                // value={sea}
              />
            </VuiBox>
            <VuiBox
              sx={{
                "& th": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    backgroundColor: "red",
                    borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                      `${borderWidth[1]} solid ${grey[700]}`,
                  },
                },
                "& .MuiTableRow-root td": {
                  backgroundColor: "transparent !important",
                  color: "white !important",
                },
              }}
            >
              {/* <Table columns={columns} rows={rows} /> */}
              {loading ? (
                <>
                  <VuiBox
                    // container
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="60vh"
                  >
                    <CircularProgress />
                  </VuiBox>
                </>
              ) : (
                <>
                  <VipTableData rows={vipListing?.data} columns={columns2} />
                  <VuiBox display="flex" justifyContent="center" alignItems="center">
                    <Pagination
                      count={Math.ceil(vipListing.recordsFiltered / Number(length))}
                      page={pages}
                      onChange={handlePageChange}
                      sx={{
                        "& .MuiPaginationItem-root": {
                          borderRadius: 8, // Customize the border-radius of each pagination item
                          backgroundColor: " #2f37ac", // Customize the background color of the selected pagination item
                          color: "#fff",
                        },
                        "& .Mui-selected": {
                          backgroundColor: "#27b9f2", // Customize the background color of the selected pagination item
                          color: "#fff", // Customize the text color of the selected pagination item
                        },
                      }}
                    />
                  </VuiBox>
                </>
              )}
            </VuiBox>
          </Card>
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
