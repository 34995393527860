import { createSlice } from "@reduxjs/toolkit";
const vipDataTable = createSlice({
  name: "vipDataTable",
  initialState: {
    vipListing: {},
    couponLimit: "",
  },
  reducers: {
    setVipDataTable: (state, action) => {
      state.vipListing = action.payload;
    },

    removeVipFromTable: (state, action) => {
      const id = action.payload;
      state.vipListing.data = state.vipListing.data.filter(({ _id }) => _id !== id);
    },
    setGlobalCouponLimit: (state, action) => {
      state.couponLimit = action.payload;
    },
  },
});

export const { setVipDataTable, removeVipFromTable, setGlobalCouponLimit } = vipDataTable.actions;
export default vipDataTable.reducer;
