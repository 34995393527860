// export const baseUrl2 = "https://tree.art/";
export const baseUrl = "http://dev.tree.art/";
// export const baseUrl2 = "http://dev.tree.art/";
export const baseUrl2 = "https://backend.tree.art/";
export const bulk = "https://import.tree.art/";

// export const baseUrl2 = "http://192.168.0.187:3000/";
// export const baseUrl = "/";
export const apiPathDev = baseUrl + "api";
export const apiPathLive = baseUrl2 + "api";
export const basePath = "/admin";
