import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";
import { useDispatch } from "react-redux";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgSignIn from "assets/images/tree-bg.png";
import { login } from "store/api/auth";
import Toast from "helpers/Toast";
import storage from "services/storage";
import { useHistory } from "react-router-dom";
import { basePath } from "config";
import { CircularProgress, FormHelperText, Grid } from "@mui/material";
// import VuiSwitch from "components/VuiSwitch";

function SignIn() {
  const dispatch = useDispatch();
  const history = useHistory();
  // window.location.reload();

  const [rememberMe, setRememberMe] = useState(true);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);

  const initialValues = {
    email: "",
    password: "",
    production: false,
  };
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string().required("Required"),
  });
  const onSubmit = async (values) => {
    setLoading(true);
    // dispatch(setCredational(values));

    dispatch(
      login({
        body: {
          email: values.email,
          password: values.password,
        },
        onSuccess: (res) => {
          // navigate("/lobby");
          const { headers, data } = res;
          setLoading(false);
          const adminUser = data;
          if (adminUser?.userType !== 2) {
            Toast.warning("you are not authorized for this area.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else {
            if (adminUser?.isEmailVerified === true) {
              Toast.success("Welcome to Admin Panel.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              storage.store("adminUser", data);
              storage.store("adminAuthToken", headers["x-auth-token"]);
              // this.props.history.replace(basePath + "/users");
              history.push(basePath + "/dashboard");
            } else {
              Toast.warning("It seems like your email is not verified.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          }
        },

        onError: (error) => {
          setLoading(false);

          Toast.error(error.response?.data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
  };
  return (
    <CoverLayout
      title="Tree | Admin Panel"
      color="white"
      description="Enter your email and password to sign in"
      // premotto="INSPIRED BY THE FUTURE:"
      // motto="Tree Admin"
      image={bgSignIn}
    >
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
          <>
            <VuiBox component="form" role="form">
              <VuiBox mb={2}>
                <VuiBox mb={1} ml={0.5}>
                  <VuiTypography
                    component="label"
                    variant="button"
                    color="white"
                    fontWeight="medium"
                  >
                    Email
                  </VuiTypography>
                </VuiBox>
                <GradientBorder
                  minWidth="100%"
                  padding="1px"
                  borderRadius={borders.borderRadius.lg}
                  backgroundImage={radialGradient(
                    palette.gradients.borderLight.main,
                    palette.gradients.borderLight.state,
                    palette.gradients.borderLight.angle
                  )}
                >
                  <VuiInput
                    type="email"
                    placeholder="Your email..."
                    fontWeight="500"
                    onChange={handleChange("email")}
                    onBlur={handleBlur("email")}
                    value={values.email}
                  />
                </GradientBorder>
                <FormHelperText error={errors.email && touched.email ? errors.email : false}>
                  {errors.email && touched.email ? errors.email : null}
                </FormHelperText>
              </VuiBox>
              <VuiBox mb={2}>
                <VuiBox mb={1} ml={0.5}>
                  <VuiTypography
                    component="label"
                    variant="button"
                    color="white"
                    fontWeight="medium"
                  >
                    Password
                  </VuiTypography>
                </VuiBox>
                <GradientBorder
                  minWidth="100%"
                  borderRadius={borders.borderRadius.lg}
                  padding="1px"
                  backgroundImage={radialGradient(
                    palette.gradients.borderLight.main,
                    palette.gradients.borderLight.state,
                    palette.gradients.borderLight.angle
                  )}
                >
                  <VuiInput
                    type="password"
                    placeholder="Your password..."
                    sx={({ typography: { size } }) => ({
                      fontSize: size.sm,
                    })}
                    onChange={handleChange("password")}
                    onBlur={handleBlur("password")}
                    value={values.password}
                    r
                  />
                </GradientBorder>
                <FormHelperText
                  error={errors.password && touched.password ? errors.password : false}
                >
                  {errors.password && touched.password ? errors.password : false}
                </FormHelperText>
              </VuiBox>

              <VuiBox mt={4} mb={1}>
                <VuiButton
                  color="info"
                  disabled={loading ? true : false}
                  fullWidth
                  onClick={handleSubmit}
                >
                  {loading ? <CircularProgress size={20} /> : " SIGN IN"}
                </VuiButton>
              </VuiBox>
              {/* <VuiBox mt={3} textAlign="center">
          <VuiTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <VuiTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="white"
              fontWeight="medium"
            >
              Sign up
            </VuiTypography>
          </VuiTypography>
        </VuiBox> */}
            </VuiBox>
          </>
        )}
      </Formik>
    </CoverLayout>
  );
}

export default SignIn;
