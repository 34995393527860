import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import userDataTable from "store/ui/userTable";
import categoryDataTable from "store/ui/category";
import bulkDataTable from "store/ui/bulk";
import affiliateDataTable from "store/ui/affiliate";
import contentDataTable from "store/ui/userContent";
import viewContentDataTable from "store/ui/viewBulkContent";
import viewBusinessDataTable from "store/ui/viewBusniessContent";
import viewUploadedBusinessDataTable from "store/ui/uploadedProfile";
import vipDataTable from "store/ui/vipTable";
import profileInfo from "../store/ui/profile";
import claimedBusiness from "store/ui/claimedBusiness";

// import loginUi from "../store/ui/loginUi";
// import userData from "../store/ui/userData";
// import userCashout from "../store/ui/cashout.slice";
// import providerData from "../store/ui/SelectedProvider";
// import { setEditUser } from "./auth/eidtProfile";
// import eidtUserSlice from "../store/auth/eidtProfile";
// import lobbyGames from "./ui/lobbyGames";
// import favoritesGames from "./ui/Favorites.slice";
// import filteringLobbyGamesSlice from "./ui/filteringLobbyGames.slice";
// import GamesSearchSlice from "./ui/GamesSearch.slice";
// import userBillingHistory from "./ui/billingHistory.slice";
const combineReducer = combineReducers({
  //   auth: authReducer,
  //   user: authUser,
  //   login: loginUi,
  userDataTable: userDataTable,
  viewContentDataTable: viewContentDataTable,
  viewBusinessDataTable: viewBusinessDataTable,
  viewUploadedBusinessDataTable: viewUploadedBusinessDataTable,
  profileInfo: profileInfo,
  contentDataTable: contentDataTable,
  vipDataTable: vipDataTable,
  categoryDataTable: categoryDataTable,
  bulkDataTable: bulkDataTable,
  affiliateDataTable: affiliateDataTable,
  claimedBusiness,
  //   providerData: providerData,
  //   editUser: eidtUserSlice,
  //   lobbyGamesResp: lobbyGames,
  //   favoritesGamesResp: favoritesGames,
  //   filterLobbyGames: filteringLobbyGamesSlice,
  //   userGamesSearch: GamesSearchSlice,
  //   billingHistory: userBillingHistory,
  //   userCashout: userCashout,
});

const store = configureStore({
  reducer: combineReducer,
});

export default store;
