import bulkHttp from "services/bulkHttp";
import http from "../../services/http";
import storage from "../../services/storage";
// import { handleErrors } from "../../helpers/error";

export const login = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.post({
        url: "/users/admin_login",
        body,
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};

export const storeAddUser = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.post({
        url: "/users",
        body,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const editAddUser = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.post({
        url: "/users/edit",
        body,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const getUser = ({ id, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        url: "/users/" + id,
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};

export const setMedia = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.post({
        url: "/media/image",
        body,
        // headers: {
        //   "x-auth-token": storage.get("adminAuthToken"),
        // },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const updateUserContent = ({ id, body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.put({
        url: "/users/add_metadata/" + id,
        body,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};

export const profileImport = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await bulkHttp.post({
        url: "api/v1/profile_import",
        body,
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
