import { useEffect, useMemo, useState } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";
// @mui material components
import { CircularProgress, Icon, Table as MuiTable, TableCell } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
//   React components
import VuiBox from "components/VuiBox";
import VuiAvatar from "components/VuiAvatar";
import VuiTypography from "components/VuiTypography";
//   React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import { Link } from "react-router-dom";
import VuiBadge from "components/VuiBadge";
import VuiButton from "components/VuiButton";
import { HiViewBoards } from "react-icons/hi";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { getUserTable } from "store/api/userTable";
// import { formatNumber } from "services/number.service";
import IconButton from "@mui/material/IconButton";
import { FaPencilAlt, FaUser, FaTrashAlt, FaDownload } from "react-icons/fa";
import { addUserVip } from "store/api/userTable";
import { deleteUser } from "store/api/userTable";
import Toast from "helpers/Toast";
import { removeDataFromTable } from "store/ui/userTable";
import { useHistory } from "react-router-dom";
import { basePath } from "config";
import { deleteCategory } from "store/api/category";
import { removeCategoryFromTable } from "store/ui/category";
import csv from "assets/images/csv2.png";
import testCsv from "assets/test.csv";
import { MdViewDay, MdViewTimeline } from "react-icons/md";
import storage from "services/storage";
// import { HiViewBoards } from "react-icons/hi";
function UploadedBusinessTableData({ columns, rows, isActive, order, perPage, currentPage, path }) {
  const history = useHistory();
  const onAddUser = (item) => {
    console.log(item);
    const link = document.createElement("a");
    link.href = item;
    link.setAttribute("download", "csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const { grey, success } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const renderColumns = columns.map(({ id, header_name, name, align, width, minWidth }, key) => {
    let pl;
    let pr;
    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns?.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    const handleDownload = () => {
      // Construct the URL to your CSV file
      const csvUrl = testCsv;

      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = csvUrl;

      // Set the download attribute and file name
      link.setAttribute("download", "data.csv");

      // Simulate a click on the anchor element to trigger the download
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
    };

    return (
      <VuiBox
        key={id}
        component="th"
        // width={width || "auto"}
        minWidth={minWidth}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="text"
        opacity={0.7}
        // onClick={name === "#" ? test : () => onClickColumn(name)}
        borderBottom={`${borderWidth[1]} solid ${grey[700]}`}
      >
        <VuiBox display="flex" justifyContent={align} alignItems={align}>
          <VuiTypography
            variant="button"
            color={"white"}
            fontWeight={isActive === name ? "bold" : "regular"}
          >
            {header_name.toUpperCase()}
          </VuiTypography>
          {/* {name === "#" ? (
            ""
          ) : isActive === name ? (
            order === "asc" ? (
              <AiOutlineArrowDown size="20px" color="white" />
            ) : (
              <AiOutlineArrowUp size="20px" color="white" />
            )
          ) : (
            ""
          )} */}
        </VuiBox>
      </VuiBox>
    );
  });
  function Author({ image, name, color, color2 }) {
    return (
      <VuiBox display="flex" alignItems="center" px={1} py={0.5}>
        <VuiBox
          mr={2}
          sx={{
            backgroundColor: color,
            borderRadius: "10px",
          }}
        >
          <VuiAvatar src={image} alt={name} size="sm" variant="rounded" />
        </VuiBox>
        <VuiBox display="flex" flexDirection="column">
          <VuiTypography variant="button" color="white" fontWeight="medium">
            {name}
          </VuiTypography>
          <VuiTypography variant="caption" color="text">
            {color2}
          </VuiTypography>
          <VuiTypography variant="caption" color="text">
            {color}
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    );
  }
  const renderRows = rows?.map((row, key) => (
    <TableRow
      key={key}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      // style={{backgroundColor: 'red'}}
    >
      {/* <TableCell sx={{ backgroundColor: "red" }} key={row.name} component="td">
        {(currentPage - 1) * perPage + key + 1}
      </TableCell> */}
      <TableCell sx={{ backgroundColor: "red" }} key={row.name} component="td">
        <Author image={row?.image} color2={row?.name} />
        {/* {new Date(row.createdAt).toLocaleDateString()} */}
      </TableCell>

      <TableCell align="center">{row?.username}</TableCell>
      <TableCell align="center">{row?.email}</TableCell>
      <TableCell align="center">
        {path === "dev" ? (
          <a target="_blank" href={`http://dev.tree.art/profile/${row.username}`}>
            Profile link
          </a>
        ) : (
          <a target="_blank" href={`https://tree.art/profile/${row.username}`}>
            Profile link
          </a>
        )}
      </TableCell>
    </TableRow>
  ));
  const noDataRows = (
    <TableRow>
      <VuiTypography component="td" color="white">
        No data found
      </VuiTypography>
    </TableRow>
  );
  return useMemo(
    () => (
      <TableContainer>
        <MuiTable>
          <VuiBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </VuiBox>
          {/* <TableBody>{renderRows}</TableBody> */}
          <TableBody>{rows?.length === 0 ? noDataRows : renderRows}</TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [columns, rows]
  );
}
// Setting default values for the props of Table
UploadedBusinessTableData.defaultProps = {
  columns: [],
  rows: [{}],
};
// Typechecking props for the Table
UploadedBusinessTableData.propType = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};
export default UploadedBusinessTableData;
