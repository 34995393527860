import { useEffect, useMemo, useState, useRef } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";
// @mui material components
import { Icon, Table as MuiTable, TableCell } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
//   React components
import VuiBox from "components/VuiBox";
import VuiAvatar from "components/VuiAvatar";
import VuiTypography from "components/VuiTypography";
//   React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import { Link } from "react-router-dom";
import VuiBadge from "components/VuiBadge";
import VuiButton from "components/VuiButton";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { getUserTable } from "store/api/userTable";
// import { formatNumber } from "services/number.service";
import IconButton from "@mui/material/IconButton";
import { FaPencilAlt, FaUser, FaTrashAlt, FaEdit } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { removeUserVip } from "store/api/userTable";
import Toast from "helpers/Toast";
import { removeVipFromTable } from "store/ui/vipTable";
import { basePath } from "config";
import VuiInput from "components/VuiInput";
import storage from "services/storage";
import GradientBorder from "examples/GradientBorder";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import { makeStyles } from "@mui/styles";
import { TagsInput } from "react-tag-input-component";
import { updateUserContent } from "store/api/auth";
import { BsSave2Fill } from "react-icons/bs";
import { MdCancel } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
  input: {
    overflow: "hidden !important", // Hide the scrollbar
    resize: "none !important", // Disable resizing
  },
}));

const containerStyle = {
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitLineClamp: 3,
};
// import LimitInput from "./LimitInput";
const TruncatedText = ({ text, maxLines }) => {
  return <span style={containerStyle}>{text}</span>;
};

function UserContentTable({ columns, rows, isActive, order, perPage, currentPage }) {
  console.log("rows,", rows);

  const history = useHistory();

  const { grey, success } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const renderColumns = columns.map(({ id, header_name, name, align, width, minWidth }, key) => {
    let pl;
    let pr;
    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns?.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <VuiBox
        key={id}
        component="th"
        // width={width || "auto"}
        minWidth={minWidth}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="text"
        opacity={0.7}
        // onClick={name === "#" ? test : () => onClickColumn(name)}
        borderBottom={`${borderWidth[1]} solid ${grey[700]}`}
      >
        <VuiBox display="flex" justifyContent={align} alignItems={align}>
          <VuiTypography
            variant="button"
            color={"white"}
            fontWeight={isActive === name ? "bold" : "regular"}
          >
            {header_name.toUpperCase()}
          </VuiTypography>
          {/* {name === "#" ? (
            ""
          ) : isActive === name ? (
            order === "asc" ? (
              <AiOutlineArrowDown size="20px" color="white" />
            ) : (
              <AiOutlineArrowUp size="20px" color="white" />
            )
          ) : (
            ""
          )} */}
        </VuiBox>
      </VuiBox>
    );
  });
  // test code by aftab for truncated description

  function Author({ image, name, email }) {
    return (
      <VuiBox display="flex" alignItems="center" px={1} py={0.5}>
        <VuiBox mr={2}>
          <VuiAvatar src={image} alt={name} size="sm" variant="rounded" />
        </VuiBox>
        <VuiBox display="flex" flexDirection="column">
          <VuiTypography variant="button" color="white" fontWeight="medium">
            {name}
          </VuiTypography>
          <VuiTypography variant="caption" color="text">
            {email}
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    );
  }

  const noDataRows = (
    <TableRow>
      <VuiTypography component="td" color="white">
        No data found
      </VuiTypography>
    </TableRow>
  );
  return useMemo(
    () => (
      <TableContainer>
        <MuiTable>
          <VuiBox component="thead">
            <TableRow style={{ background: "#090d29" }}>{renderColumns}</TableRow>
          </VuiBox>
          {/* <TableBody>{renderRows}</TableBody> */}
          <TableBody>
            {rows.length === 0 ? noDataRows : rows.map((row) => <Row key={row._id} row={row} />)}
          </TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [columns, rows]
  );
}

const Row = ({ row }) => {
  const path = storage.get("baseUrlPath");
  const [isEditing, setIsEditing] = useState(false);
  const [selected, setSelected] = useState(row.tags);
  const [formData, setFormData] = useState({
    title: row.title,
    description: row.description,
  });
  console.log(formData, "formData");
  console.log(selected, "selecete");
  const [tempData, setTempData] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTempData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const dispatch = useDispatch();

  const handleSave = (id) => {
    console.log(id);
    //
    setFormData(tempData);
    console.log("formdata", formData);
    setTempData({}); // Clear temporary changes after saving
    dispatch(
      updateUserContent({
        id,
        body: {
          title: tempData.title,
          description: tempData.description,
          tags: selected,
        },
        onSuccess: (res) => {
          console.log("res", res);
          setIsEditing(false);

          // setloading(false);
          Toast.success("Updated Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
        onError: (error) => {
          setIsEditing(false);

          Toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          // setloading(false);
          console.log("error", error);
          setTempData({}); // Clear temporary changes on cancel
          setSelected(row.tags);
        },
      })
    );
  };

  const handleCancel = () => {
    setTempData({}); // Clear temporary changes on cancel
    setSelected(row.tags);
    setIsEditing(false);
  };

  const handleEditToggle = () => {
    setIsEditing(true);
    setTempData(formData);
  };

  const handleKeyDown = (event) => {
    if (event.key === " " && event.target.value.trim() !== "") {
      // Prevent the space from being added to the input value
      event.preventDefault();
      const tags = [...selected, event.target.value.trim()];
      // Add the current input as a new tag
      setSelected(tags);
      // setFormData((prev) => ({ ...prev, tags }));
      event.target.value = "";
    }
    // console.log("selected", ...formData.tags, event.target.value.trim()); // Clear the input value
  };

  const classes = useStyles();
  return (
    <TableRow
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      // style={{backgroundColor: 'red'}}
    >
      <TableCell sx={{ backgroundColor: "red" }} component="td">
        {new Date(row.createdAt).toLocaleDateString()}
      </TableCell>
      <TableCell align="center">
        <VuiBox display="flex" justifyContent="center" alignItems="center" px={1} py={0.5}>
          <VuiBox mr={2}>
            <VuiAvatar src={row.thumbnail} alt={row.thumbnail} size="sm" variant="rounded" />
          </VuiBox>
        </VuiBox>
      </TableCell>
      <TableCell align="center">
        {row.title || row.title === "" ? (
          isEditing ? (
            <GradientBorder
              minWidth="100%"
              padding="1px"
              borderRadius={borders.borderRadius.lg}
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                // className={classes.input}
                type="text"
                // placeholder="Name..."
                name="title"
                multiline
                maxRows={4}
                minRows={4}
                sx={{
                  overFlow: "hidden",
                }}
                // overflow="hidden"
                // fontWeight="500"
                value={tempData.title}
                onChange={handleInputChange}
              />
            </GradientBorder>
          ) : tempData.title || formData.title === "" ? (
            "---"
          ) : (
            <TruncatedText text={tempData.title || formData.title} maxLines={4} />
          )
        ) : (
          "---"
        )}
      </TableCell>
      <TableCell align="center">
        {row.description || row.title === "" ? (
          isEditing ? (
            <GradientBorder
              minWidth="100%"
              padding="1px"
              borderRadius={borders.borderRadius.lg}
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                // className={classes.input}
                type="text"
                // placeholder="Name..."
                name="description"
                multiline
                maxRows={4}
                minRows={4}
                sx={{
                  overFlow: "hidden",
                }}
                // overflow="hidden"
                // fontWeight="500"
                value={tempData.description}
                onChange={handleInputChange}
              />
            </GradientBorder>
          ) : tempData.description || formData.description === "" ? (
            "---"
          ) : (
            <TruncatedText text={tempData.description || formData.description} maxLines={4} />
          )
        ) : (
          "---"
        )}
      </TableCell>
      <TableCell align="center">{row.mediaType ? row.mediaType : "---"}</TableCell>
      <TableCell align="center">
        {isEditing ? (
          <>
            <div className="tag-input-div">
              <TagsInput
                value={selected}
                onChange={setSelected}
                name="tag"
                placeHolder="add tag"
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyDown}
                style={{ backgroundColor: "red" }}
              />
            </div>
          </>
        ) : selected.length ? (
          <>
            <div style={containerStyle}>
              {selected.map((item, index) => {
                return (
                  <>
                    {item}
                    {index !== row.tags.length - 1 && ", "}
                  </>
                );
              })}
            </div>
          </>
        ) : (
          "---"
        )}
        {/* {selected.length ? (
          <>
            {selected.map((item, index) => {
              return (
                <>
                  {item}
                  {index !== row.tags.length - 1 && ", "}
                </>
              );
            })}
          </>
        ) : (
          "---"
        )} */}
      </TableCell>
      <TableCell align="center">
        {row.collaborators.map((item, index) => {
          return (
            <>
              <VuiTypography variant="button" color="white" fontWeight="medium">
                {item.name}
              </VuiTypography>
              {index !== row.collaborators.length - 1 && ", "}
            </>
          );
        })}
      </TableCell>
      <TableCell align="center">
        {path === "dev" ? (
          <a className="atagin" href={`http://dev.tree.art/post/${row._id}`} target="_blank">
            {" "}
            Post link
          </a>
        ) : (
          <a target="_blank" href={`https://tree.art/post/${row._id}`}>
            Post link
          </a>
        )}
      </TableCell>
      <TableCell align="center">
        {isEditing ? (
          <>
            <VuiButton
              onClick={() => handleSave(row._id)}
              component="label"
              color={"success"}
              sx={{ padding: "0.75rem 0rem", minWidth: "40px" }}
            >
              <BsSave2Fill />
            </VuiButton>
            <VuiButton
              onClick={handleCancel}
              component="label"
              color={"error"}
              sx={{ marginLeft: "5%", padding: "0.75rem 0rem", minWidth: "40px" }}
            >
              <MdCancel />
            </VuiButton>
          </>
        ) : (
          <VuiButton
            onClick={() => handleEditToggle()}
            component="label"
            color={"info"}
            sx={{ padding: "0.75rem 0rem", minWidth: "40px" }}
          >
            <FaEdit />
          </VuiButton>
        )}
      </TableCell>
    </TableRow>
  );
};
// Setting default values for the props of Table
UserContentTable.defaultProps = {
  columns: [],
  rows: [],
};
// Typechecking props for the Table
UserContentTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};
export default UserContentTable;
