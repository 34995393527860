import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AddCategoryForm from "./components/addCategoryForm";

const AddCategory = () => {
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <VuiBox py={3}>
          <AddCategoryForm member="Preset" />
        </VuiBox>
      </DashboardLayout>
    </>
  );
};

export default AddCategory;
