import { createSlice } from "@reduxjs/toolkit";
const viewBusinessDataTable = createSlice({
  name: "viewBusinessDataTable",
  initialState: {
    viewBusinessDataListing: {},
  },
  reducers: {
    setViewBusinessDataTable: (state, action) => {
      state.viewBusinessDataListing = action.payload; // Update the value in the store
    },
  },
});

export const { setViewBusinessDataTable } = viewBusinessDataTable.actions;
export default viewBusinessDataTable.reducer;
