import { createSlice } from "@reduxjs/toolkit";

const userDataTable = createSlice({
  name: "userDataTable",
  initialState: {
    userListing: {},
  },
  reducers: {
    setUserDataTable: (state, action) => {
      state.userListing = action.payload; // Update the value in the store
    },

    removeDataFromTable: (state, action) => {
      const id = action.payload;
      state.userListing.data = state.userListing.data.filter(({ _id }) => _id !== id);
    },

    toggleUserType: (state, action) => {
      const id = action.payload;

      const user = state.userListing.data.find((user) => user._id === id);
      if (user) {
        user.userType = user.userType === 1 ? 3 : user.userType === 3 ? 1 : user.userType;
      }
    },
  },
});

export const { setUserDataTable, removeDataFromTable, toggleUserType } = userDataTable.actions;
export default userDataTable.reducer;
