import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import {
  Card,
  CircularProgress,
  FormControl,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import linearGradient from "assets/theme/functions/linearGradient";

// Vision UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";

// Dashboard layout components
import WelcomeMark from "layouts/dashboard/components/WelcomeMark";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";
import SatisfactionRate from "layouts/dashboard/components/SatisfactionRate";
import ReferralTracking from "layouts/dashboard/components/ReferralTracking";

// React icons
import { IoIosRocket } from "react-icons/io";
import { IoGlobe } from "react-icons/io5";
import { IoBuild } from "react-icons/io5";
import { IoWallet } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import { FaApple, FaShoppingCart } from "react-icons/fa";

// Data
import LineChart from "examples/Charts/LineCharts/LineChart";
import BarChart from "examples/Charts/BarCharts/BarChart";
import { lineChartDataDashboard } from "layouts/dashboard/data/lineChartData";
import { lineChartOptionsDashboard } from "layouts/dashboard/data/lineChartOptions";
import { barChartDataDashboard } from "layouts/dashboard/data/barChartData";
import { barChartOptionsDashboard } from "layouts/dashboard/data/barChartOptions";
import ChartComponent from "./components/Chart";
import { useEffect, useState } from "react";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import { GrUserNew } from "react-icons/gr";
import { FaClipboardUser } from "react-icons/fa6";
import { MdSignpost, MdWeb } from "react-icons/md";
import { newUser } from "store/api/analytics";
import { useDispatch } from "react-redux";
import { usersPostsByDay } from "store/api/analytics";
import { newUsersToday } from "store/api/analytics";
import { newPostToday } from "store/api/analytics";
import { PostsByDayFilter } from "store/api/analytics";
import { PostsByDayOnCreated } from "store/api/analytics";
import { ActiveUserNow } from "store/api/analytics";
import { TotalPost } from "store/api/analytics";
import { BsFillSignpostSplitFill } from "react-icons/bs";
import { ActiveUsersByDay } from "store/api/analytics";
import { TotalUserProfileType } from "store/api/analytics";
import { ImProfile } from "react-icons/im";
import { IoBusiness } from "react-icons/io5";
import Toast from "helpers/Toast";
import { ClaimedUser } from "store/api/analytics";
import { createdOnGet } from "store/api/userTable";
import { CgWebsite } from "react-icons/cg";
import { RiAdminFill } from "react-icons/ri";
function Dashboard() {
  const dispatch = useDispatch();
  const [option, setOption] = useState([]);
  const [data, setdata] = useState([]);
  const [dataCreatedon, setdataCreatedon] = useState([]);
  const [newUserToday, setNewUserToday] = useState("");
  const [personalProfile, setPersonalProfile] = useState("");
  const [businessProfile, setBusinessProfile] = useState("");
  const [businessProfile2, setBusinessProfile2] = useState([]);
  const [ClaimedProfile, setClaimedProfile] = useState("");
  const [claimingProfile, setClaimingProfile] = useState("");
  const [active, setActive] = useState("");
  const [totalPost, setTotalPost] = useState("");

  const [newPostsToday, setNewPostToday] = useState("");
  const [data2, setdata2] = useState([]);
  const [option2, setOption2] = useState([]);
  const [data6, setdata6] = useState([]);
  const [option6, setOption6] = useState([]);
  const [data4, setdata4] = useState([]);
  const [option4, setOption4] = useState([]);
  const [data5, setdata5] = useState([]);
  const [option5, setOption5] = useState([]);
  const [data3, setdata3] = useState([]);
  const [mediaType, setMediaType] = useState("all");
  const [contentType, setContentType] = useState("all");
  const [createdOn, setCreatedOn] = useState("all");

  const [option3, setOption3] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [loading5, setLoading5] = useState(false);
  const [loading6, setLoading6] = useState(false);

  const { gradients } = colors;
  const { cardContent } = gradients;
  const currentDate = new Date(); // Current date and time
  const oneWeekAgo = new Date(currentDate);
  oneWeekAgo.setDate(currentDate.getDate() - 1); // Subtract 7 days
  const oneMonthAgo = new Date(currentDate);
  oneMonthAgo.setDate(currentDate.getDate() - 30);

  const getFormatedDate = (date) => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [startDate, setStartDate] = useState(oneMonthAgo);
  const [endDate, setEndDate] = useState(oneWeekAgo);
  const [startDateClaimed, setStartDateClaimed] = useState(oneMonthAgo);
  const [endDateClaimed, setEndDateClaimed] = useState(oneWeekAgo);
  const [startDateActive, setStartDateActive] = useState(oneMonthAgo);
  const [endDateActive, setEndDateActive] = useState(oneWeekAgo);
  const [startDatePostFilter, setStartDatePostFilter] = useState(oneMonthAgo);
  const [endDatePostFilter, setEndDatePostFilter] = useState(oneWeekAgo);
  const [startDateCreatedOn, setStartDateCreatedOn] = useState(oneMonthAgo);
  const [endDateCreatedOn, setEndDateCreatedOn] = useState(oneWeekAgo);
  const [startDateActive2, setStartDateActive2] = useState(oneMonthAgo);
  const [endDateActive2, setEndDateActive2] = useState(oneWeekAgo);
  const handleStartDateClaimedChange = (event) => {
    const newStartDate = event.target.value;
    if (newStartDate < getFormatedDate(currentDate)) {
      setStartDateClaimed(newStartDate);
      console.log("ali");
      setLoading6(true);

      dispatch(
        ClaimedUser({
          body: {
            startDate: newStartDate,
            endDate: endDateClaimed,
          },
          onSuccess: (res) => {
            const counts = res?.data?.data.byDate.map((item) => item.count);
            setdata6(counts);
            setLoading6(false);

            const options = res?.data?.data.byDate.map((item) => item.date);
            setOption6(options);
            console.log(counts, options);
          },
          onError: (err) => {
            setLoading6(false);
            Toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          },
        })
      );
    }
  };
  const handleEndDateClaimedChange = (event) => {
    const newEndDate = event.target.value;

    // console.log("New End Date: " + newEndDate);
    if (
      newEndDate > getFormatedDate(startDateClaimed) &&
      newEndDate < getFormatedDate(currentDate)
    ) {
      setEndDateClaimed(newEndDate);
      console.log("baloch", newEndDate);
      setLoading6(true);

      dispatch(
        ClaimedUser({
          body: {
            startDate: startDateClaimed,
            endDate: newEndDate,
          },
          onSuccess: (res) => {
            const counts = res?.data?.data.byDate.map((item) => item.count);
            setdata6(counts);
            setLoading6(false);

            const options = res?.data?.data.byDate.map((item) => item.date);
            setOption6(options);
            console.log(counts, options);
          },
          onError: (err) => {
            setLoading6(false);
            Toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          },
        })
      );
    } else {
      // console.log("False");
    }
  };
  const handleStartDateChangeActiveUser2 = (event) => {
    const newStartDate = event.target.value;
    if (newStartDate < getFormatedDate(currentDate)) {
      setStartDateActive2(newStartDate);
      setLoading3(true);

      dispatch(
        ActiveUsersByDay({
          body: {
            startDate: newStartDate,
            endDate: endDateActive2,
          },
          onSuccess: (res) => {
            const counts = res?.data?.data.map((item) => item.count);
            setdata3(counts);
            setLoading3(false);

            const options = res?.data?.data.map((item) => item.date);
            setOption3(options);
            console.log(counts, options);
          },
          onError: (err) => {
            Toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            setLoading3(false);
          },
        })
      );
    }
  };

  const handleEndDateChangeActiveUser2 = (event) => {
    const newEndDate = event.target.value;

    if (
      newEndDate > getFormatedDate(startDateActive2) &&
      newEndDate < getFormatedDate(currentDate)
    ) {
      setEndDateActive2(newEndDate);
      setLoading3(true);
      dispatch(
        ActiveUsersByDay({
          body: {
            startDate: startDateActive2,
            endDate: newEndDate,
          },
          onSuccess: (res) => {
            const counts = res?.data?.data.map((item) => item.count);
            setdata3(counts);
            setLoading3(false);

            const options = res?.data?.data.map((item) => item.date);
            setOption3(options);
            console.log(counts, options);
          },
          onError: (err) => {
            setLoading3(false);
            Toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          },
        })
      );
    }
  };
  const handleStartDateChangePostFilter = (event) => {
    const newStartDate = event.target.value;
    if (newStartDate < getFormatedDate(currentDate)) {
      setStartDatePostFilter(newStartDate);
      setLoading4(true);

      dispatch(
        PostsByDayFilter({
          body: {
            startDate: newStartDate,
            endDate: endDatePostFilter,
            mediaType: mediaType === "all" ? "" : mediaType,
            paidContentType: contentType === "all" ? "" : contentType,
          },
          onSuccess: (res) => {
            const counts = res?.data?.data.map((item) => item.count);
            setdata4(counts);

            const options = res?.data?.data.map((item) => item.date);
            setOption4(options);
            console.log(counts, options);
            setLoading4(false);
          },
          onError: (err) => {
            setLoading4(false);
            Toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          },
        })
      );
    }
  };

  const handleEndDateChangePostFilter = (event) => {
    const newEndDate = event.target.value;

    if (
      newEndDate > getFormatedDate(startDatePostFilter) &&
      newEndDate < getFormatedDate(currentDate)
    ) {
      setEndDatePostFilter(newEndDate);
      setLoading4(true);

      dispatch(
        PostsByDayFilter({
          body: {
            startDate: startDatePostFilter,
            endDate: newEndDate,
            mediaType: mediaType === "all" ? "" : mediaType,
            paidContentType: contentType === "all" ? "" : contentType,
          },
          onSuccess: (res) => {
            const counts = res?.data?.data.map((item) => item.count);
            setdata4(counts);
            setLoading4(false);

            const options = res?.data?.data.map((item) => item.date);
            setOption4(options);
            console.log(counts, options);
          },
          onError: (err) => {
            setLoading4(false);
            Toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          },
        })
      );
    }
  };
  const handleStartDateChangeCreatedOn = (event) => {
    const newStartDate = event.target.value;
    if (newStartDate < getFormatedDate(currentDate)) {
      setStartDateCreatedOn(newStartDate);
      setLoading5(true);

      dispatch(
        PostsByDayOnCreated({
          body: {
            startDate: newStartDate,
            endDate: endDateCreatedOn,
            createdOn: createdOn === "all" ? "" : createdOn,
          },
          onSuccess: (res) => {
            const counts = res?.data?.data.map((item) => item.count);
            setdata5(counts);

            const options = res?.data?.data.map((item) => item.date);
            setOption5(options);
            console.log(counts, options);
            setLoading5(false);
          },
          onError: (err) => {
            setLoading5(false);
            Toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          },
        })
      );
    }
  };

  const handleEndDateChangeCreatedOn = (event) => {
    const newEndDate = event.target.value;

    if (
      newEndDate > getFormatedDate(startDateCreatedOn) &&
      newEndDate < getFormatedDate(currentDate)
    ) {
      setEndDateCreatedOn(newEndDate);
      setLoading5(true);

      dispatch(
        PostsByDayOnCreated({
          body: {
            startDate: startDateCreatedOn,
            endDate: newEndDate,
            createdOn: createdOn === "all" ? "" : createdOn,
          },
          onSuccess: (res) => {
            const counts = res?.data?.data.map((item) => item.count);
            setdata5(counts);
            setLoading5(false);

            const options = res?.data?.data.map((item) => item.date);
            setOption5(options);
            console.log(counts, options);
          },
          onError: (err) => {
            setLoading5(false);
            Toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          },
        })
      );
    }
  };
  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    if (newStartDate < getFormatedDate(currentDate)) {
      setStartDate(newStartDate);
      console.log("ali");
      setLoading(true);

      dispatch(
        newUser({
          body: {
            startDate: newStartDate,
            endDate,
          },
          onSuccess: (res) => {
            const counts = res?.data?.data.map((item) => item.count);
            setdata(counts);
            setLoading(false);

            const options = res?.data?.data.map((item) => item.date);
            setOption(options);
            console.log(counts, options);
          },
          onError: (err) => {
            setLoading(false);
            Toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          },
        })
      );
    }
  };

  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;
    console.log("baloch2", newEndDate);

    // console.log("New End Date: " + newEndDate);
    if (newEndDate > getFormatedDate(startDate) && newEndDate < getFormatedDate(currentDate)) {
      setEndDate(newEndDate);
      console.log("baloch", newEndDate);
      setLoading(true);

      dispatch(
        newUser({
          body: {
            startDate,
            endDate: newEndDate,
          },
          onSuccess: (res) => {
            const counts = res?.data?.data.map((item) => item.count);
            setdata(counts);
            setLoading(false);

            const options = res?.data?.data.map((item) => item.date);
            setOption(options);
            console.log(counts, options);
          },
          onError: (err) => {
            setLoading(false);
            Toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          },
        })
      );
    } else {
      // console.log("False");
    }
  };
  const handleStartDateChangeActiveUser = (event) => {
    const newStartDate = event.target.value;
    if (newStartDate < getFormatedDate(currentDate)) {
      setStartDateActive(newStartDate);
      setLoading2(true);

      dispatch(
        usersPostsByDay({
          body: {
            startDate: newStartDate,
            endDate: endDateActive,
          },
          onSuccess: (res) => {
            const counts = res?.data?.data.map((item) => item.count);
            setdata2(counts);
            setLoading2(false);

            const options = res?.data?.data.map((item) => item.date);
            setOption2(options);
            console.log(counts, options);
          },
          onError: (err) => {
            setLoading2(false);
            Toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          },
        })
      );
    }
  };

  const handleEndDateChangeActiveUser = (event) => {
    const newEndDate = event.target.value;

    if (
      newEndDate > getFormatedDate(startDateActive) &&
      newEndDate < getFormatedDate(currentDate)
    ) {
      setEndDateActive(newEndDate);
      setLoading2(true);

      dispatch(
        usersPostsByDay({
          body: {
            startDate: startDateActive,
            endDate: newEndDate,
          },
          onSuccess: (res) => {
            const counts = res?.data?.data.map((item) => item.count);
            setdata2(counts);
            setLoading2(false);

            const options = res?.data?.data.map((item) => item.date);
            setOption2(options);
            console.log(counts, options);
          },
          onError: (err) => {
            Toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            setLoading2(false);
          },
        })
      );
    }
  };
  useEffect(() => {
    getActiveUserNow();
    getTotalPostOnTree();
    getTotalUserProfileType();
    getDateNewUser();
    getDateClaimedUser();
    getDateUsersPostsByDay();
    getActiveUserByDay();
    getNewUsersToday();
    getNewPostToday();
    getDateByContentType();
    getDateByCreatedOn();
    getCreatedOn();
  }, []);

  const getCreatedOn = () => {
    dispatch(
      createdOnGet({
        onSuccess: (res) => {
          console.log("=====>on", res.data.data[0]);
          setdataCreatedon(res.data.data[0]);
        },
        onError: (err) => {
          console.log(err);
        },
      })
    );
  };
  const getNewPostToday = () => {
    dispatch(
      newPostToday({
        body: {
          startDate,
          endDate,
        },
        onSuccess: (res) => {
          console.log("today", res.data.posts);
          setNewPostToday(res?.data?.posts);
        },
        onError: (err) => {
          Toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
  };
  const getNewUsersToday = () => {
    dispatch(
      newUsersToday({
        body: {
          startDate,
          endDate,
        },
        onSuccess: (res) => {
          console.log("today", res.data.users);
          setNewUserToday(res?.data?.users);
        },
        onError: (err) => {
          Toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
  };
  const getDateNewUser = () => {
    setLoading(true);
    dispatch(
      newUser({
        body: {
          startDate,
          endDate,
        },
        onSuccess: (res) => {
          const counts = res?.data?.data.map((item) => item.count);
          setdata(counts);
          setLoading(false);

          const options = res?.data?.data.map((item) => item.date);
          setOption(options);
          console.log(counts, options);
        },
        onError: (err) => {
          setLoading(false);
          Toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
  };
  const getDateClaimedUser = () => {
    setLoading6(true);
    dispatch(
      ClaimedUser({
        body: {
          startDate: startDateClaimed,
          endDate: endDateClaimed,
        },
        onSuccess: (res) => {
          console.log("resClaimed", res);
          const counts = res?.data?.data.byDate.map((item) => item.count);
          console.log("resClaimed==", counts);
          setClaimedProfile(res?.data?.data.totalClaimedProfiles);
          setClaimingProfile(res?.data?.data.totalProfiles);

          setdata6(counts);
          setLoading6(false);

          const options = res?.data?.data.byDate.map((item) => item.date);

          setOption6(options);
          console.log("resClaimed12", counts, options);
        },
        onError: (err) => {
          setLoading6(false);
          Toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
  };
  const getDateUsersPostsByDay = () => {
    setLoading2(true);
    dispatch(
      usersPostsByDay({
        body: {
          startDate: startDateActive,
          endDate: endDateActive,
        },
        onSuccess: (res) => {
          const counts = res?.data?.data.map((item) => item.count);
          setdata2(counts);
          setLoading2(false);

          const options = res?.data?.data.map((item) => item.date);
          setOption2(options);
          console.log(counts, options);
        },
        onError: (err) => {
          setLoading2(false);
          Toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
  };
  const getActiveUserByDay = () => {
    setLoading3(true);
    dispatch(
      ActiveUsersByDay({
        body: {
          startDate: startDateActive2,
          endDate: endDateActive2,
        },
        onSuccess: (res) => {
          const counts = res?.data?.data.map((item) => item.count);
          setdata3(counts);
          setLoading3(false);

          const options = res?.data?.data.map((item) => item.date);
          setOption3(options);
          console.log(counts, options);
        },
        onError: (err) => {
          setLoading3(false);
          Toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
  };
  const getDateByContentType = () => {
    setLoading4(true);
    dispatch(
      PostsByDayFilter({
        body: {
          startDate: startDatePostFilter,
          endDate: endDatePostFilter,
          mediaType: mediaType === "all" ? "" : mediaType,
          paidContentType: contentType === "all" ? "" : contentType,
        },
        onSuccess: (res) => {
          const counts = res?.data?.data.map((item) => item.count);
          setdata4(counts);
          setLoading4(false);

          const options = res?.data?.data.map((item) => item.date);
          setOption4(options);
          console.log(counts, options);
        },
        onError: (err) => {
          setLoading4(false);
          Toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
  };
  const getActiveUserNow = () => {
    dispatch(
      ActiveUserNow({
        onSuccess: (res) => {
          console.log("atcive", res?.data?.users);
          setActive(res?.data?.users);
        },
        onError: (err) => {
          Toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
  };
  const getTotalUserProfileType = () => {
    dispatch(
      TotalUserProfileType({
        onSuccess: (res) => {
          console.log("total2", res?.data.users[0].count);
          console.log("total1111", res?.data.users[1].count);
          setBusinessProfile(res?.data.users[1].count);
          setBusinessProfile2(res?.data.users);
          setPersonalProfile(res?.data.users[0].count);
          // setActive(res?.data?.users);
        },
        onError: (err) => {
          Toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
  };
  const getTotalPostOnTree = () => {
    dispatch(
      TotalPost({
        onSuccess: (res) => {
          console.log("total", res?.data?.posts);
          setTotalPost(res?.data?.posts);
        },
        onError: (err) => {
          Toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
  };
  const getDateByCreatedOn = () => {
    setLoading5(true);
    dispatch(
      PostsByDayOnCreated({
        body: {
          startDate: startDatePostFilter,
          endDate: endDatePostFilter,
          createdOn: createdOn === "all" ? "" : createdOn,
        },
        onSuccess: (res) => {
          const counts = res?.data?.data.map((item) => item.count);
          setdata5(counts);
          setLoading5(false);

          const options = res?.data?.data.map((item) => item.date);
          setOption5(options);
          console.log(counts, options);
        },
        onError: (err) => {
          setLoading4(false);
          Toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
  };
  const handleChangeMediaType = (event) => {
    setMediaType(event.target.value);
    console.log(event.target.value);
    setLoading4(true);
    dispatch(
      PostsByDayFilter({
        body: {
          startDate: startDatePostFilter,
          endDate: endDatePostFilter,
          mediaType: event.target.value === "all" ? "" : event.target.value,
          paidContentType: contentType === "all" ? "" : contentType,
        },
        onSuccess: (res) => {
          const counts = res?.data?.data.map((item) => item.count);
          setdata4(counts);
          setLoading4(false);

          const options = res?.data?.data.map((item) => item.date);
          setOption4(options);
          console.log(counts, options);
        },
        onError: (err) => {
          setLoading4(false);
          Toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
    // setpages(1);
  };
  const handleChangeContentType = (event) => {
    setContentType(event.target.value);
    console.log(event.target.value);
    setLoading4(true);
    dispatch(
      PostsByDayFilter({
        body: {
          startDate: startDatePostFilter,
          endDate: endDatePostFilter,
          mediaType: mediaType === "all" ? "" : mediaType,
          paidContentType: event.target.value === "all" ? "" : event.target.value,
        },
        onSuccess: (res) => {
          const counts = res?.data?.data.map((item) => item.count);
          setdata4(counts);
          setLoading4(false);

          const options = res?.data?.data.map((item) => item.date);
          setOption4(options);
          console.log(counts, options);
        },
        onError: (err) => {
          setLoading4(false);
          Toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
    // setpages(1);
  };
  const handleChangeCreatedOn = (event) => {
    setCreatedOn(event.target.value);
    console.log(event.target.value);
    setLoading5(true);
    dispatch(
      PostsByDayOnCreated({
        body: {
          startDate: startDateCreatedOn,
          endDate: endDateCreatedOn,
          createdOn: event.target.value === "all" ? "" : event.target.value,
        },
        onSuccess: (res) => {
          const counts = res?.data?.data.map((item) => item.count);
          setdata5(counts);
          setLoading5(false);

          const options = res?.data?.data.map((item) => item.date);
          setOption5(options);
          console.log(counts, options);
        },
        onError: (err) => {
          setLoading5(false);
          Toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
    // setpages(1);
  };
  console.log("claimed", data6, option6);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                paddingSize="17px"
                title={{ text: "New users SignUp Today", fontWeight: "regular" }}
                count={newUserToday}
                icon={{ color: "info", component: <GrUserNew size="22px" color="white" /> }}
              />
            </Grid>

            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                paddingSize="17px"
                title={{ text: "Active Users Now " }}
                count={active}
                // percentage={{ color: "success" }}
                icon={{ color: "info", component: <FaClipboardUser size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                paddingSize="17px"
                title={{ text: "Total Claimed Businesses", fontWeight: "regular" }}
                count={ClaimedProfile}
                icon={{ color: "info", component: <GrUserNew size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                paddingSize="17px"
                title={{ text: "Total Claiming Businesses", fontWeight: "regular" }}
                count={claimingProfile}
                icon={{ color: "info", component: <GrUserNew size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                paddingSize="17px"
                title={{ text: "Claimed Businesses Percentage ", fontWeight: "regular" }}
                count={claimingProfile ? <>{(ClaimedProfile / claimingProfile) * 100}% </> : "0%"}
                icon={{ color: "info", component: <GrUserNew size="22px" color="white" /> }}
              />
            </Grid>
            {/* <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                paddingSize="17px"
                title={{ text: "Personal Profile on Tree " }}
                count={personalProfile}
                // percentage={{ color: "success" }}
                icon={{ color: "info", component: <ImProfile size="22px" color="white" /> }}
              />
            </Grid> */}
            {businessProfile2.map((item) => {
              return (
                <Grid item xs={12} md={6} xl={3}>
                  <MiniStatisticsCard
                    paddingSize="17px"
                    title={{
                      text:
                        item._id === "businessProfiles"
                          ? "Business Profile On Tree"
                          : "Personal Profile On Tree",
                    }}
                    count={item.count}
                    // percentage={{ color: "success" }}
                    icon={{ color: "info", component: <IoBusiness size="22px" color="white" /> }}
                  />
                </Grid>
              );
            })}
            {/* <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                paddingSize="17px"
                title={{ text: "Business Profile on Tree " }}
                count={businessProfile}
                // percentage={{ color: "success" }}
                icon={{ color: "info", component: <IoBusiness size="22px" color="white" /> }}
              />
            </Grid> */}
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                paddingSize="17px"
                title={{ text: "Total users Post Today" }}
                count={newPostsToday}
                icon={{ color: "info", component: <MdSignpost size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                paddingSize="17px"
                title={{ text: "Total Posts on Tree " }}
                count={totalPost}
                // percentage={{ color: "success" }}
                icon={{
                  color: "info",
                  component: <BsFillSignpostSplitFill size="22px" color="white" />,
                }}
              />
            </Grid>
          </Grid>
        </VuiBox>
        <VuiBox mb={3}>
          <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
            Created On Method
          </VuiTypography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                paddingSize="17px"
                title={{ text: " Admin" }}
                count={dataCreatedon.admin}
                // percentage={{ color: "success" }}
                icon={{
                  color: "info",
                  component: <RiAdminFill size="22px" color="white" />,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                paddingSize="17px"
                title={{ text: " Business Import Tool" }}
                count={dataCreatedon.businessImport}
                // percentage={{ color: "success" }}
                icon={{
                  color: "info",
                  component: <BsFillSignpostSplitFill size="22px" color="white" />,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                paddingSize="17px"
                title={{ text: " IOS" }}
                count={dataCreatedon.iOS}
                // percentage={{ color: "success" }}
                icon={{
                  color: "info",
                  component: <FaApple size="22px" color="white" />,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                paddingSize="17px"
                title={{ text: " WEB" }}
                count={dataCreatedon.web}
                // percentage={{ color: "success" }}
                icon={{
                  color: "info",
                  component: <MdWeb size="22px" color="white" />,
                }}
              />
            </Grid>
          </Grid>
        </VuiBox>
        {/* <VuiBox mb={3}>
          <Grid container spacing="18px">
            <Grid item xs={12} lg={12} xl={5}>
              <WelcomeMark />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <SatisfactionRate />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
              <ReferralTracking />
            </Grid>
          </Grid>
        </VuiBox> */}
        {/* <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} xl={7}>
              <Card>
                <VuiBox sx={{ height: "100%" }}>
                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    Sales Overview
                  </VuiTypography>
                  <VuiBox display="flex" alignItems="center" mb="40px">
                    <VuiTypography variant="button" color="success" fontWeight="bold">
                      +5% more{" "}
                      <VuiTypography variant="button" color="text" fontWeight="regular">
                        in 2021
                      </VuiTypography>
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox sx={{ height: "310px" }}>
                    <LineChart
                      lineChartData={lineChartDataDashboard}
                      lineChartOptions={lineChartOptionsDashboard}
                    />
                  </VuiBox>
                </VuiBox>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6} xl={5}>
              <Card>
                <VuiBox>
                  <VuiBox
                    mb="24px"
                    height="220px"
                    sx={{
                      background: linearGradient(
                        cardContent.main,
                        cardContent.state,
                        cardContent.deg
                      ),
                      borderRadius: "20px",
                    }}
                  >
                    <BarChart
                      barChartData={barChartDataDashboard}
                      barChartOptions={barChartOptionsDashboard}
                    />
                  </VuiBox>
                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    Active Users
                  </VuiTypography>
                  <VuiBox display="flex" alignItems="center" mb="40px">
                    <VuiTypography variant="button" color="success" fontWeight="bold">
                      (+23){" "}
                      <VuiTypography variant="button" color="text" fontWeight="regular">
                        than last week
                      </VuiTypography>
                    </VuiTypography>
                  </VuiBox>
                  <Grid container spacing="50px">
                    <Grid item xs={6} md={3} lg={3}>
                      <Stack
                        direction="row"
                        spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                        mb="6px"
                      >
                        <VuiBox
                          bgColor="info"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
                        >
                          <IoWallet color="#fff" size="12px" />
                        </VuiBox>
                        <VuiTypography color="text" variant="button" fontWeight="medium">
                          Users
                        </VuiTypography>
                      </Stack>
                      <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                        32,984
                      </VuiTypography>
                      <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <Stack
                        direction="row"
                        spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                        mb="6px"
                      >
                        <VuiBox
                          bgColor="info"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
                        >
                          <IoIosRocket color="#fff" size="12px" />
                        </VuiBox>
                        <VuiTypography color="text" variant="button" fontWeight="medium">
                          Clicks
                        </VuiTypography>
                      </Stack>
                      <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                        2,42M
                      </VuiTypography>
                      <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <Stack
                        direction="row"
                        spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                        mb="6px"
                      >
                        <VuiBox
                          bgColor="info"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
                        >
                          <FaShoppingCart color="#fff" size="12px" />
                        </VuiBox>
                        <VuiTypography color="text" variant="button" fontWeight="medium">
                          Sales
                        </VuiTypography>
                      </Stack>
                      <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                        2,400$
                      </VuiTypography>
                      <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <Stack
                        direction="row"
                        spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                        mb="6px"
                      >
                        <VuiBox
                          bgColor="info"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
                        >
                          <IoBuild color="#fff" size="12px" />
                        </VuiBox>
                        <VuiTypography color="text" variant="button" fontWeight="medium">
                          Items
                        </VuiTypography>
                      </Stack>
                      <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                        320
                      </VuiTypography>
                      <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
                    </Grid>
                  </Grid>
                </VuiBox>
              </Card>
            </Grid>
          </Grid>
        </VuiBox> */}
        {/* <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
          <Grid item xs={12} md={6} lg={8}>
            <Projects />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OrderOverview />
          </Grid>
        </Grid> */}

        <VuiBox mb={3}>
          <Grid item xs={12} lg={12} xl={12}>
            <Card>
              <VuiBox sx={{ height: "100%" }}>
                <VuiBox display="flex" justifyContent="space-between">
                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    New Users Overview
                  </VuiTypography>

                  <Stack direction={"row"} spacing={2}>
                    <VuiBox>
                      <VuiTypography variant="caption" color="white" fontWeight="bold" mb="5px">
                        Start Date
                      </VuiTypography>
                      <VuiInput
                        type="date"
                        value={getFormatedDate(startDate)}
                        onChange={handleStartDateChange}
                      />
                    </VuiBox>
                    <VuiBox>
                      <VuiTypography variant="caption" color="white" fontWeight="bold" mb="5px">
                        End Date
                      </VuiTypography>
                      <VuiInput
                        onChange={handleEndDateChange}
                        value={getFormatedDate(endDate)}
                        type="date"
                      />
                    </VuiBox>
                    <VuiBox display="flex" alignItems="end"></VuiBox>
                  </Stack>
                </VuiBox>
                {loading ? (
                  <>
                    {/* <Card> */}
                    <VuiBox
                      // container
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      minHeight="310px"
                    >
                      <CircularProgress />
                    </VuiBox>
                    {/* </Card> */}
                  </>
                ) : (
                  <VuiBox sx={{ height: "310px" }}>
                    <ChartComponent options={option} data={data} colors={"#34cb99"} />
                  </VuiBox>
                )}
              </VuiBox>
            </Card>
          </Grid>
        </VuiBox>
        <VuiBox mb={3}>
          <Grid item xs={12} lg={12} xl={12}>
            <Card>
              <VuiBox sx={{ height: "100%" }}>
                <VuiBox display="flex" justifyContent="space-between">
                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    Claimed User Overview
                  </VuiTypography>

                  <Stack direction={"row"} spacing={2}>
                    <VuiBox>
                      <VuiTypography variant="caption" color="white" fontWeight="bold" mb="5px">
                        Start Date
                      </VuiTypography>
                      <VuiInput
                        type="date"
                        value={getFormatedDate(startDateClaimed)}
                        onChange={handleStartDateClaimedChange}
                      />
                    </VuiBox>
                    <VuiBox>
                      <VuiTypography variant="caption" color="white" fontWeight="bold" mb="5px">
                        End Date
                      </VuiTypography>
                      <VuiInput
                        onChange={handleEndDateClaimedChange}
                        value={getFormatedDate(endDateClaimed)}
                        type="date"
                      />
                    </VuiBox>
                    <VuiBox display="flex" alignItems="end"></VuiBox>
                  </Stack>
                </VuiBox>
                {loading6 ? (
                  <>
                    {/* <Card> */}
                    <VuiBox
                      // container
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      minHeight="310px"
                    >
                      <CircularProgress />
                    </VuiBox>
                    {/* </Card> */}
                  </>
                ) : (
                  <VuiBox sx={{ height: "310px" }}>
                    <ChartComponent options={option6} data={data6} colors={"#34cb99"} />
                  </VuiBox>
                )}
              </VuiBox>
            </Card>
          </Grid>
        </VuiBox>

        <VuiBox mb={3}>
          <Grid item xs={12} lg={12} xl={12}>
            <Card>
              <VuiBox sx={{ height: "100%" }}>
                <VuiBox display="flex" justifyContent="space-between">
                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    Post by Users Overview
                  </VuiTypography>
                  <Stack direction={"row"} spacing={2}>
                    <VuiBox>
                      <VuiTypography variant="caption" color="white" fontWeight="bold" mb="5px">
                        Start Date
                      </VuiTypography>
                      <VuiInput
                        type="date"
                        value={getFormatedDate(startDateActive)}
                        onChange={handleStartDateChangeActiveUser}
                      />
                    </VuiBox>
                    <VuiBox>
                      <VuiTypography variant="caption" color="white" fontWeight="bold" mb="5px">
                        End Date
                      </VuiTypography>
                      <VuiInput
                        onChange={handleEndDateChangeActiveUser}
                        value={getFormatedDate(endDateActive)}
                        type="date"
                      />
                    </VuiBox>
                    <VuiBox display="flex" alignItems="end"></VuiBox>
                  </Stack>
                </VuiBox>
                {loading2 ? (
                  <>
                    {/* <Card> */}
                    <VuiBox
                      // container
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      minHeight="310px"
                    >
                      <CircularProgress />
                    </VuiBox>
                    {/* </Card> */}
                  </>
                ) : (
                  <VuiBox sx={{ height: "310px" }}>
                    <ChartComponent options={option2} data={data2} colors={"#2CD9FF"} />
                  </VuiBox>
                )}
              </VuiBox>
            </Card>
          </Grid>
        </VuiBox>
        <VuiBox mb={3}>
          <Grid item xs={12} lg={12} xl={12}>
            <Card>
              <VuiBox sx={{ height: "100%" }}>
                <VuiBox display="flex" justifyContent="space-between">
                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    Active Users Overview
                  </VuiTypography>
                  <Stack direction={"row"} spacing={2}>
                    <VuiBox>
                      <VuiTypography variant="caption" color="white" fontWeight="bold" mb="5px">
                        Start Date
                      </VuiTypography>
                      <VuiInput
                        type="date"
                        value={getFormatedDate(startDateActive2)}
                        onChange={handleStartDateChangeActiveUser2}
                      />
                    </VuiBox>
                    <VuiBox>
                      <VuiTypography variant="caption" color="white" fontWeight="bold" mb="5px">
                        End Date
                      </VuiTypography>
                      <VuiInput
                        onChange={handleEndDateChangeActiveUser2}
                        value={getFormatedDate(endDateActive2)}
                        type="date"
                      />
                    </VuiBox>
                    <VuiBox display="flex" alignItems="end"></VuiBox>
                  </Stack>
                </VuiBox>
                {loading3 ? (
                  <>
                    {/* <Card> */}
                    <VuiBox
                      // container
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      minHeight="310px"
                    >
                      <CircularProgress />
                    </VuiBox>
                    {/* </Card> */}
                  </>
                ) : (
                  <VuiBox sx={{ height: "310px" }}>
                    <ChartComponent options={option3} data={data3} colors={"#0075FF"} />
                  </VuiBox>
                )}
              </VuiBox>
            </Card>
          </Grid>
        </VuiBox>
        <VuiBox mb={3}>
          <Grid item xs={12} lg={12} xl={12}>
            <Card>
              <VuiBox sx={{ height: "100%" }}>
                <VuiBox display="flex" justifyContent="space-between">
                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    Content Posted Overview
                  </VuiTypography>
                  <Stack direction={"row"} spacing={2}>
                    <VuiBox display="flex " flexDirection="column">
                      <VuiTypography
                        variant="caption"
                        color="white"
                        fontWeight="bold"
                        mt="9px"
                        mb="5px"
                      >
                        Media Type
                      </VuiTypography>
                      <FormControl
                        variant="outlined"
                        size="small"
                        sx={{
                          ".MuiInputBase-formControl": {
                            backgroundColor: "transparent !important",
                            color: "white !important",
                            border: "1px solid white !important",
                          },
                        }}
                      >
                        {/* <InputLabel sx={{color: 'white !important'}}>Per Page</InputLabel> */}
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={mediaType}
                          label="Per Page Items"
                          onChange={handleChangeMediaType}
                          sx={{
                            ".MuiInputBase-root-outlined": {
                              backgroundColor: "transparent !important",
                            },
                          }}
                        >
                          <MenuItem value={"all"}>All</MenuItem>
                          <MenuItem value={"audio"}>Audio</MenuItem>
                          <MenuItem value={"video"}>Video</MenuItem>
                          <MenuItem value={"image"}>Image</MenuItem>
                          <MenuItem value={"texteditor"}>Texteditor</MenuItem>
                        </Select>
                      </FormControl>
                    </VuiBox>
                    <VuiBox display="flex " flexDirection="column">
                      <VuiTypography
                        variant="caption"
                        color="white"
                        fontWeight="bold"
                        mt="9px"
                        mb="5px"
                      >
                        Content Type
                      </VuiTypography>
                      <FormControl
                        variant="outlined"
                        size="small"
                        sx={{
                          ".MuiInputBase-formControl": {
                            backgroundColor: "transparent !important",
                            color: "white !important",
                            border: "1px solid white !important",
                          },
                        }}
                      >
                        {/* <InputLabel sx={{color: 'white !important'}}>Per Page</InputLabel> */}
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={contentType}
                          label="Per Page Items"
                          onChange={handleChangeContentType}
                          sx={{
                            ".MuiInputBase-root-outlined": {
                              backgroundColor: "transparent !important",
                            },
                          }}
                        >
                          <MenuItem value={"all"}>All</MenuItem>
                          <MenuItem value={"free"}>Free</MenuItem>
                          <MenuItem value={"subscription"}>Subscription</MenuItem>
                          <MenuItem value={"purchasable"}>Purchasable</MenuItem>
                        </Select>
                      </FormControl>
                    </VuiBox>
                  </Stack>

                  <Stack direction={"row"} spacing={2}>
                    <VuiBox>
                      <VuiTypography variant="caption" color="white" fontWeight="bold" mb="5px">
                        Start Date
                      </VuiTypography>
                      <VuiInput
                        type="date"
                        value={getFormatedDate(startDatePostFilter)}
                        onChange={handleStartDateChangePostFilter}
                      />
                    </VuiBox>
                    <VuiBox>
                      <VuiTypography variant="caption" color="white" fontWeight="bold" mb="5px">
                        End Date
                      </VuiTypography>
                      <VuiInput
                        onChange={handleEndDateChangePostFilter}
                        value={getFormatedDate(endDatePostFilter)}
                        type="date"
                      />
                    </VuiBox>
                    <VuiBox display="flex" alignItems="end"></VuiBox>
                  </Stack>
                </VuiBox>
                {loading4 ? (
                  <>
                    {/* <Card> */}
                    <VuiBox
                      // container
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      minHeight="310px"
                    >
                      <CircularProgress />
                    </VuiBox>
                    {/* </Card> */}
                  </>
                ) : (
                  <VuiBox sx={{ height: "310px" }}>
                    <ChartComponent options={option4} data={data4} colors={"#2CD9FF"} />
                  </VuiBox>
                )}
              </VuiBox>
            </Card>
          </Grid>
        </VuiBox>
        <VuiBox mb={3}>
          <Grid item xs={12} lg={12} xl={12}>
            <Card>
              <VuiBox sx={{ height: "100%" }}>
                <VuiBox display="flex" justifyContent="space-between">
                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    Post Created On Overview
                  </VuiTypography>
                  <Stack direction={"row"} spacing={2}>
                    <VuiBox display="flex " flexDirection="column">
                      <VuiTypography
                        variant="caption"
                        color="white"
                        fontWeight="bold"
                        mt="9px"
                        mb="5px"
                      >
                        Created On
                      </VuiTypography>
                      <FormControl
                        variant="outlined"
                        size="small"
                        sx={{
                          ".MuiInputBase-formControl": {
                            backgroundColor: "transparent !important",
                            color: "white !important",
                            border: "1px solid white !important",
                          },
                        }}
                      >
                        {/* <InputLabel sx={{color: 'white !important'}}>Per Page</InputLabel> */}
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={createdOn}
                          label="Per Page Items"
                          onChange={handleChangeCreatedOn}
                          sx={{
                            ".MuiInputBase-root-outlined": {
                              backgroundColor: "transparent !important",
                            },
                          }}
                        >
                          <MenuItem value={"all"}>All</MenuItem>
                          <MenuItem value={"iOS"}>IOS</MenuItem>
                          <MenuItem value={"android"}>Android</MenuItem>
                          <MenuItem value={"web"}>Web</MenuItem>
                          <MenuItem value={"bulk_upload"}>Bulk Import Tool</MenuItem>
                        </Select>
                      </FormControl>
                    </VuiBox>
                  </Stack>

                  <Stack direction={"row"} spacing={2}>
                    <VuiBox>
                      <VuiTypography variant="caption" color="white" fontWeight="bold" mb="5px">
                        Start Date
                      </VuiTypography>
                      <VuiInput
                        type="date"
                        value={getFormatedDate(startDateCreatedOn)}
                        onChange={handleStartDateChangeCreatedOn}
                      />
                    </VuiBox>
                    <VuiBox>
                      <VuiTypography variant="caption" color="white" fontWeight="bold" mb="5px">
                        End Date
                      </VuiTypography>
                      <VuiInput
                        onChange={handleEndDateChangeCreatedOn}
                        value={getFormatedDate(endDateCreatedOn)}
                        type="date"
                      />
                    </VuiBox>
                    <VuiBox display="flex" alignItems="end"></VuiBox>
                  </Stack>
                </VuiBox>
                {loading5 ? (
                  <>
                    {/* <Card> */}
                    <VuiBox
                      // container
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      minHeight="310px"
                    >
                      <CircularProgress />
                    </VuiBox>
                    {/* </Card> */}
                  </>
                ) : (
                  <VuiBox sx={{ height: "310px" }}>
                    <ChartComponent options={option5} data={data5} colors={"#34cb99"} />
                  </VuiBox>
                )}
              </VuiBox>
            </Card>
          </Grid>
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
