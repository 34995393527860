import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";
import { useDispatch } from "react-redux";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgSignIn from "assets/images/tree-bg.png";
import { login } from "store/api/auth";
import Toast from "helpers/Toast";
import storage from "services/storage";
import { useHistory } from "react-router-dom";
import { basePath } from "config";
import { CircularProgress, FormHelperText, Grid } from "@mui/material";
// import VuiSwitch from "components/VuiSwitch";
import getCountries from "utils/countries.util";
import { AffiliateLogin } from "store/api/affiliate";
import { verifylogin } from "store/api/affiliate";

function AffiliateSignIn() {
  const dispatch = useDispatch();
  const countries = getCountries();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("");
  const [verifyType, setVerifyType] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [code, setCode] = useState("+1");
  const [contactInfo, setContactInfo] = useState("");

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const selectCode = (code) => {
    setCode(code);
    setIsVisible(false);
  };
  const changeTab = (index) => {
    setActiveTab(index);
  };

  const tabs = [
    { label: "email", filter: "email" },
    { label: "phone", filter: "phone" },
  ];
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  const initialValues = {
    email: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Required"),
  });
  const onSubmit = async (values) => {
    setLoading(true);
    console.log("values", values);
    // dispatch(setCredational(values));

    dispatch(
      AffiliateLogin({
        body: {
          contactInfo: values.email,
        },
        onSuccess: (res) => {
          setLoading(false);
          setContactInfo(values.email);
          setVerifyType("email");

          setActiveTab("verify");
        },

        onError: (error) => {
          setLoading(false);

          Toast.error(error.response?.data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
  };
  const initialValuesPhone = {
    phone: "",
  };
  const validationSchemaPhone = Yup.object({
    phone: Yup.string()
      .required("Required")
      .matches(/^[0-9]+$/, "Phone number must contain only digits"),
  });
  const onSubmitPhone = async (values) => {
    setLoading(true);
    // dispatch(setCredational(values));
    const number = code + values.phone;
    dispatch(
      AffiliateLogin({
        body: {
          contactInfo: number,
        },
        onSuccess: (res) => {
          setLoading(false);
          setContactInfo(number);
          setVerifyType("phone");

          setActiveTab("verify");
        },

        onError: (error) => {
          setLoading(false);

          Toast.error(error.response?.data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
  };
  const onSubmitVerify = async (values) => {
    setLoading3(true);
    console.log(contactInfo);

    dispatch(
      verifylogin({
        contactInfo,
        verificationCode: values.code,
        onSuccess: (res) => {
          setLoading3(false);
          history.push(basePath + "/dashboard");
        },

        onError: (error) => {
          setLoading3(false);

          Toast.error(error?.response?.data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
  };

  const initialValuesVerify = {
    code: "",
  };
  const validationSchemaVerify = Yup.object({
    code: Yup.string().required("Required"),
  });
  const handleResend = () => {
    setLoading2(true);

    dispatch(
      AffiliateLogin({
        body: {
          contactInfo,
        },
        onSuccess: (res) => {
          Toast.success("OTP sent");
          setLoading2(false);
        },

        onError: (error) => {
          setLoading2(false);

          Toast.error(error?.response?.data);
        },
      })
    );
  };
  return (
    <CoverLayout
      title="Tree | Affiliate Panel"
      color="white"
      description={
        activeTab === "email"
          ? "Enter your email to sign in"
          : activeTab === "phone"
          ? "Enter your Phone to sign in"
          : activeTab === "verify"
          ? ""
          : "Sign In as Email or Phone "
      }
      // premotto="INSPIRED BY THE FUTURE:"
      // motto="Tree Admin"
      image={bgSignIn}
    >
      {activeTab === "email" ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
            <>
              <VuiBox component="form" role="form">
                <VuiBox mb={2}>
                  <VuiBox mb={1} ml={0.5}>
                    <VuiTypography
                      component="label"
                      variant="button"
                      color="white"
                      fontWeight="medium"
                    >
                      Email
                    </VuiTypography>
                  </VuiBox>
                  <GradientBorder
                    minWidth="100%"
                    padding="1px"
                    borderRadius={borders.borderRadius.lg}
                    backgroundImage={radialGradient(
                      palette.gradients.borderLight.main,
                      palette.gradients.borderLight.state,
                      palette.gradients.borderLight.angle
                    )}
                  >
                    <VuiInput
                      type="email"
                      placeholder="Your email..."
                      fontWeight="500"
                      onChange={handleChange("email")}
                      onBlur={handleBlur("email")}
                      value={values.email}
                    />
                  </GradientBorder>
                  <FormHelperText error={errors.email && touched.email ? errors.email : false}>
                    {errors.email && touched.email ? errors.email : null}
                  </FormHelperText>
                </VuiBox>

                <VuiBox mt={4} mb={1}>
                  <VuiButton
                    color="info"
                    disabled={loading ? true : false}
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {loading ? <CircularProgress size={20} /> : " Next"}
                  </VuiButton>
                </VuiBox>
                <VuiBox mt={4} mb={1}>
                  <VuiButton color="info" fullWidth onClick={() => changeTab("")}>
                    Back
                  </VuiButton>
                </VuiBox>
                {/* <VuiBox mt={3} textAlign="center">
          <VuiTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <VuiTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="white"
              fontWeight="medium"
            >
              Sign up
            </VuiTypography>
          </VuiTypography>
        </VuiBox> */}
              </VuiBox>
            </>
          )}
        </Formik>
      ) : activeTab === "phone" ? (
        <Formik
          initialValues={initialValuesPhone}
          validationSchema={validationSchemaPhone}
          onSubmit={onSubmitPhone}
        >
          {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
            <>
              <VuiBox component="form" role="form">
                <VuiBox mb={2}>
                  <VuiBox mb={1} ml={0.5}>
                    <VuiTypography
                      component="label"
                      variant="button"
                      color="white"
                      fontWeight="medium"
                    >
                      Phone
                    </VuiTypography>
                  </VuiBox>
                  <div className="pr">
                    <div className="field-phone-code">
                      <div onClick={toggleVisibility} className="phone-code">
                        {code}
                      </div>
                      <input
                        // type="email"
                        onChange={handleChange("phone")}
                        onBlur={handleBlur("phone")}
                        value={values.phone}
                        placeholder="Type your phone number"
                      />
                    </div>

                    {isVisible && (
                      <div className="phone-dropdown">
                        {countries.map((item) => (
                          <>
                            <div
                              className="phone-dropdown-list"
                              onClick={(code) => selectCode(item.dialCode)}
                            >
                              <div className="phone-dropdown-img">
                                <img
                                  alt=""
                                  width={20}
                                  height={20}
                                  style={{ height: "20px", width: "20px" }}
                                  src={`data:image/svg+xml;utf8,${encodeURIComponent(item.flag)}`}
                                />
                              </div>
                              <div className="phone-dropdown-code">
                                <p> {item.country}</p>
                                <p>{item.dialCode}</p>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                  </div>
                  <FormHelperText error={errors.phone && touched.phone ? errors.phone : false}>
                    {errors.phone && touched.phone ? errors.phone : null}
                  </FormHelperText>
                </VuiBox>

                <VuiBox mt={4} mb={1}>
                  <VuiButton
                    color="info"
                    disabled={loading ? true : false}
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {loading ? <CircularProgress size={20} /> : " Next"}
                  </VuiButton>
                </VuiBox>
                <VuiBox mt={2} mb={1}>
                  <VuiButton color="info" fullWidth onClick={() => changeTab("")}>
                    Back
                  </VuiButton>
                </VuiBox>
              </VuiBox>
            </>
          )}
        </Formik>
      ) : activeTab === "verify" ? (
        <>
          <div>
            <VuiTypography
              textAlign="center !important"
              mx="auto"
              sx={({ typography: { size }, functions: { pxToRem } }) => ({
                fontWeight: "bold",
                fontSize: size.sm,
              })}
              color="white"
            >
              {verifyType === "email" ? " Check your mail" : "Check your phone"}
            </VuiTypography>
            <VuiTypography
              textAlign="center !important"
              mx="auto"
              sx={({ typography: { size }, functions: { pxToRem } }) => ({
                fontWeight: "bold",
                fontSize: size.sm,
              })}
              color="white"
            >
              {verifyType === "email"
                ? "A verification email has been sent "
                : "A verification SMS has been sent"}
            </VuiTypography>
          </div>
          <Formik
            initialValues={initialValuesVerify}
            validationSchema={validationSchemaVerify}
            onSubmit={onSubmitVerify}
          >
            {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
              <>
                <VuiBox mb={2}>
                  <VuiBox mb={1} ml={0.5}>
                    <VuiTypography
                      component="label"
                      variant="button"
                      color="white"
                      fontWeight="medium"
                    >
                      Code
                    </VuiTypography>
                  </VuiBox>
                  <GradientBorder
                    minWidth="100%"
                    padding="1px"
                    borderRadius={borders.borderRadius.lg}
                    backgroundImage={radialGradient(
                      palette.gradients.borderLight.main,
                      palette.gradients.borderLight.state,
                      palette.gradients.borderLight.angle
                    )}
                  >
                    <VuiInput
                      placeholder="Enter your verification code"
                      fontWeight="500"
                      onChange={handleChange("code")}
                      onBlur={handleBlur("code")}
                      value={values.code}
                    />
                  </GradientBorder>
                  <FormHelperText error={errors.code && touched.code ? errors.code : false}>
                    {errors.code && touched.code ? errors.code : null}
                  </FormHelperText>
                </VuiBox>

                <VuiBox mt={4} mb={1}>
                  <VuiButton
                    color="info"
                    disabled={loading3 ? true : false}
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {loading3 ? <CircularProgress size={20} /> : " Next"}
                  </VuiButton>
                </VuiBox>
                <VuiBox mt={4} mb={1}>
                  <VuiButton
                    color="info"
                    fullWidth
                    onClick={handleResend}
                    disabled={loading2 ? true : false}
                  >
                    {loading2 ? <CircularProgress size={20} /> : " Resend"}
                  </VuiButton>
                </VuiBox>
              </>
            )}
          </Formik>
        </>
      ) : (
        <>
          <VuiBox>
            <div className="toggle-Button2 ">
              <button onClick={() => changeTab("email")} className="dev2">
                Continue with Email
              </button>
              <button onClick={() => changeTab("phone")} className="dev2">
                Continue with Phone
              </button>
            </div>
          </VuiBox>
        </>
      )}
    </CoverLayout>
  );
}

export default AffiliateSignIn;
