import LineChart from "examples/Charts/LineCharts/LineChart";
import React from "react";

const ChartComponent = ({ data, options, colors }) => {
  const lineChartDataDashboard = [
    {
      data: data,
    },
  ];
  const lineChartOptionsDashboard = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: options,
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "10px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "10px",
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      strokeDashArray: 5,
      borderColor: "#56577A",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [],
      },
      colors: [colors, "#2CD9FF"],
    },
    colors: [colors, "#2CD9FF"],
  };
  return (
    <>
      <LineChart
        lineChartData={lineChartDataDashboard}
        lineChartOptions={lineChartOptionsDashboard}
      />
    </>
  );
};

export default ChartComponent;
