import { useEffect, useMemo, useState } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";
// @mui material components
import { CircularProgress, Icon, Table as MuiTable, TableCell } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
//   React components
import VuiBox from "components/VuiBox";
import VuiAvatar from "components/VuiAvatar";
import VuiTypography from "components/VuiTypography";
//   React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import { Link } from "react-router-dom";
import VuiBadge from "components/VuiBadge";
import VuiButton from "components/VuiButton";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { getUserTable } from "store/api/userTable";
// import { formatNumber } from "services/number.service";
import IconButton from "@mui/material/IconButton";
import { FaPencilAlt, FaUser, FaTrashAlt } from "react-icons/fa";
import { addUserVip } from "store/api/userTable";
import { deleteUser } from "store/api/userTable";
import Toast from "helpers/Toast";
import { removeDataFromTable } from "store/ui/userTable";
import { useHistory } from "react-router-dom";
import { basePath } from "config";
import { deleteCategory } from "store/api/category";
import { removeCategoryFromTable } from "store/ui/category";

function CatgoryTableData({ columns, rows, isActive, order, perPage, currentPage }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState({});
  const onDelete = (userId) => {
    setLoading((prevLoading) => ({ ...prevLoading, [userId]: true }));

    dispatch(
      deleteCategory({
        userId,
        onSuccess: (res) => {
          setLoading((prevLoading) => ({ ...prevLoading, [userId]: false }));

          Toast.success("Feed preset deleted ", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          dispatch(removeCategoryFromTable(userId));
        },
        onError: (error) => {
          setLoading((prevLoading) => ({ ...prevLoading, [userId]: false }));

          Toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
      })
    );
  };

  const { grey, success } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const renderColumns = columns.map(({ id, header_name, name, align, width }, key) => {
    let pl;
    let pr;
    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns?.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <VuiBox
        key={id}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="text"
        opacity={0.7}
        // onClick={name === "#" ? test : () => onClickColumn(name)}
        borderBottom={`${borderWidth[1]} solid ${grey[700]}`}
      >
        <VuiBox display="flex" justifyContent={align} alignItems={align}>
          <VuiTypography
            variant="button"
            color={"white"}
            fontWeight={isActive === name ? "bold" : "regular"}
          >
            {header_name.toUpperCase()}
          </VuiTypography>
          {/* {name === "#" ? (
            ""
          ) : isActive === name ? (
            order === "asc" ? (
              <AiOutlineArrowDown size="20px" color="white" />
            ) : (
              <AiOutlineArrowUp size="20px" color="white" />
            )
          ) : (
            ""
          )} */}
        </VuiBox>
      </VuiBox>
    );
  });
  function Author({ image, name, color }) {
    return (
      <VuiBox display="flex" alignItems="center" px={1} py={0.5}>
        <VuiBox
          mr={2}
          sx={{
            backgroundColor: color,
            borderRadius: "10px",
          }}
        >
          <VuiAvatar src={image} alt={name} size="lg" variant="rounded" />
        </VuiBox>
        <VuiBox display="flex" flexDirection="column">
          <VuiTypography variant="button" color="white" fontWeight="medium">
            {name}
          </VuiTypography>
          <VuiTypography variant="caption" color="text">
            {color}
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    );
  }
  const renderRows = rows?.map((row, key) => (
    <TableRow
      key={key}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      // style={{backgroundColor: 'red'}}
    >
      {/* <TableCell sx={{ backgroundColor: "red" }} key={row.name} component="td">
        {(currentPage - 1) * perPage + key + 1}
      </TableCell> */}
      <TableCell sx={{ backgroundColor: "red" }} key={row.name} component="td">
        <Author name={row?.name} image={row?.image} color={row?.color} />
      </TableCell>
      {/* <TableCell align="center">
        <VuiTypography variant="button" color="white" fontWeight="medium">
          {row?.isBlocked === true ? (
            <VuiBadge
              variant="standard"
              badgeContent="Blocked"
              color="success"
              size="md"
              container
              sx={({ palette: { white, error }, borders: { borderRadius, borderWidth } }) => ({
                background: error.main,
                border: `${borderWidth[1]} solid ${error.main}`,
                borderRadius: borderRadius.md,
                // color: error.main,
              })}
            />
          ) : (
            <VuiBadge
              variant="standard"
              badgeContent="Active"
              size="md"
              container
              sx={({ palette: { white, success }, borders: { borderRadius, borderWidth } }) => ({
                background: success.main,
                border: `${borderWidth[1]} solid ${success.main}`,
                borderRadius: borderRadius.md,
                color: white.main,
              })}
            />
          )}
        </VuiTypography>
      </TableCell> */}
      {/* <TableCell>
        <VuiTypography variant="button" color="white" fontWeight="medium">
          {row?.isFlag ? (
            <VuiBadge
              variant="standard"
              badgeContent="Active"
              color="success"
              size="md"
              container
              sx={({ palette: { white, success }, borders: { borderRadius, borderWidth } }) => ({
                background: success.main,
                border: `${borderWidth[1]} solid ${success.main}`,
                borderRadius: borderRadius.md,
                color: white.main,
              })}
            />
          ) : (
            <VuiBadge
              variant="standard"
              badgeContent={"In Active"}
              size="md"
              container
              sx={({ palette: { white, error }, borders: { borderRadius, borderWidth } }) => ({
                background: error.main,
                border: `${borderWidth[1]} solid ${error.main}`,
                borderRadius: borderRadius.md,
                // color: error.main,
              })}
            />
          )}
        </VuiTypography>
      </TableCell> */}
      {/* <TableCell align="center">{row?.role}</TableCell> */}
      {/* <TableCell align="center">{formatNumber(row?.coins?.purchasedWallet)}</TableCell> */}
      {/* <TableCell align="center">{formatNumber(row?.coins?.winWallet)}</TableCell> */}
      {/* <TableCell align="center">{row?.gems}</TableCell> */}
      {/* <TableCell align="center">{row?.coins?.winWallet + row?.coins?.purchasedWallet}</TableCell> */}
      {/* <TableCell align="center">{row?.phone}</TableCell> */}
      {/* <TableCell align="center">{row?.dateofbirth}</TableCell> */}
      <TableCell
        align="center"
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5%",
          paddingBottom: "5%",
        }}
      >
        <div
          className="icons-usertable"
          onClick={() => {
            history.push(`${basePath}/edit-preset/${row._id}`);
          }}
        >
          <FaPencilAlt />
        </div>

        <div
          className="icons-usertable color3"
          onClick={() => {
            onDelete(row._id);
          }}
        >
          {loading[row._id] ? "l" : <FaTrashAlt />}
        </div>

        {/* <p style={{ marginLeft: "11%" }}>logout</p> */}

        {/* <VuiButton component={Link} to={`/users/`} color="info">
            <VuiTypography variant="button" color="white" fontWeight="regular">
              View
            </VuiTypography>
          </VuiButton> */}
      </TableCell>
    </TableRow>
  ));
  const noDataRows = (
    <TableRow>
      <VuiTypography component="td" color="white">
        No data found
      </VuiTypography>
    </TableRow>
  );
  return useMemo(
    () => (
      <TableContainer>
        <MuiTable>
          <VuiBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </VuiBox>
          {/* <TableBody>{renderRows}</TableBody> */}
          <TableBody>{rows?.length === 0 ? noDataRows : renderRows}</TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [columns, rows]
  );
}
// Setting default values for the props of Table
CatgoryTableData.defaultProps = {
  columns: [],
  rows: [{}],
};
// Typechecking props for the Table
CatgoryTableData.propType = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};
export default CatgoryTableData;
