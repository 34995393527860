import { setBulkDataTable } from "store/ui/bulk";
import http from "../../services/http";
import storage from "../../services/storage";
import { setprofileInfo } from "store/ui/profile";
import { setContentDataTable } from "store/ui/userContent";
import { setViewContentDataTable } from "store/ui/viewBulkContent";
import { setViewBusinessDataTable } from "store/ui/viewBusniessContent";
import { setViewUploadedBusinessDataTable } from "store/ui/uploadedProfile";
// import { handleErrors } from "../../helpers/error";

export const sendBulkData = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.post({
        url: "/bulk_upload",
        body,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};

export const bulkSearch = ({ pageNumber, pageSize, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        url: `/bulk_upload?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });
      dispatch(setBulkDataTable(res?.data));
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const userProfileInfo = ({ id, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        url: "/bulk_upload/" + id,

        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
      dispatch(setprofileInfo(res?.data.data));
    } catch (error) {
      dispatch(setprofileInfo(""));

      if (onError) onError(error);
    }
  };
};
export const ProfileContent = ({ id, pageNumber, pageSize, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        url: `/bulk_upload/post?id=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });
      dispatch(setContentDataTable(res?.data));
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const ViewProfileBulkData = ({ id, pageNumber, pageSize, onSuccess, onError, link }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        url: `/bulk_upload/file?pageNumber=${pageNumber}&pageSize=${pageSize}&link=${link}`,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });
      dispatch(setViewContentDataTable(res?.data));
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const businessBulkTable = ({ id, pageNumber, pageSize, onSuccess, onError, link }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        url: `/profile_upload?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });
      dispatch(setViewBusinessDataTable(res?.data));
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const UploadedBusinessBulkTable = ({
  id,
  pageNumber,
  pageSize,
  onSuccess,
  onError,
  link,
}) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        // url: `/profile_upload?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        url: "/profile_upload/data/" + id,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });
      dispatch(setViewUploadedBusinessDataTable(res?.data));
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
