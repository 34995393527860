import { createSlice } from "@reduxjs/toolkit";
const viewContentDataTable = createSlice({
  name: "viewContentDataTable",
  initialState: {
    viewContentUserListing: {},
  },
  reducers: {
    setViewContentDataTable: (state, action) => {
      state.viewContentUserListing = action.payload; // Update the value in the store
    },
  },
});

export const { setViewContentDataTable } = viewContentDataTable.actions;
export default viewContentDataTable.reducer;
