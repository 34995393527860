import { setUserDataTable } from "store/ui/userTable";
import http from "../../services/http";
import storage from "../../services/storage";
import { setVipDataTable } from "store/ui/vipTable";
import { setGlobalCouponLimit } from "store/ui/vipTable";
import { setClaimedBusiness } from "store/ui/claimedBusiness";
// import { handleErrors } from "../../helpers/error";

export const getUserTable = ({ body, onSuccess, draw, search, length, start, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        url: `/users?draw=${draw}&search[value]=${search}&length=${length}&start=${start}`,
      });

      onSuccess(res);

      //   console.log("login", res.data, res.headers);
      dispatch(setUserDataTable(res?.data));
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const getvipTable = ({ body, onSuccess, draw, search, length, start, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        url: `/users/vips?draw=${draw}&search[value]=${search}&length=${length}&start=${start}`,
      });

      onSuccess(res);

      //   console.log("login", res.data, res.headers);
      dispatch(setVipDataTable(res?.data));
    } catch (error) {
      if (onError) onError(error);
    }
  };
};

export const addUserVip = ({ body, onSuccess, draw, search, length, start, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.put({
        url: "/users/create_affiliate_user",
        body,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const removeUserVip = ({ body, onSuccess, draw, search, length, start, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.put({
        url: "/users/remove_affiliate_user",
        body,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};

export const deleteUser = ({ body, onSuccess, userId, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.delete({
        url: "/admin/user/" + userId,

        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};

export const getUserInfo = ({ body, onSuccess, id, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        url: "/users/admin/" + id,

        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const getGlobalCouponLimit = ({ onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        url: `/users/global_coupon_limit`,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);

      dispatch(setGlobalCouponLimit(res?.data.globalLimit));
    } catch (error) {
      if (onError) onError(error);
    }
  };
};

export const postGlobalCouponLimit = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.post({
        url: `/users/set_global_coupon_limit`,
        body,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);

      // console.log("setGlobalCouponLimit", res);
      // dispatch(setGlobalCouponLimit(res?.data.globalLimit));
    } catch (error) {
      if (onError) onError(error);
    }
  };
};

export const postCreateAffiliateUser = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.put({
        url: `/users/create_affiliate_user`,
        body,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);

      // console.log("setGlobalCouponLimit", res);
      // dispatch(setGlobalCouponLimit(res?.data.globalLimit));
    } catch (error) {
      if (onError) onError(error);
    }
  };
};

export const getClaimedTable = ({ onSuccess, pageNumber, pageSize, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        url: `/analytics/claimed_business?pageNum=${pageNumber}&pageSize=${pageSize}`,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);

      dispatch(setClaimedBusiness(res?.data));
    } catch (error) {
      if (onError) onError(error);
    }
  };
};

export const createdOnGet = ({ onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({
        url: "/analytics/created_on",
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
export const addAffiliate = ({ body, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.post({
        url: "/users/affiliate",
        body,
        headers: {
          "x-auth-token": storage.get("adminAuthToken"),
        },
      });

      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
    }
  };
};
